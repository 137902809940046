import Image, { StaticImageData } from 'next/image';

import GaLogoWithPAMPSvg from './svgs/ga-logo-with-pamp.svg';

export const IconGaLogoWithPAMP = () => (
  <Image
    src={GaLogoWithPAMPSvg as StaticImageData}
    alt="Gold Avenue with PAMP"
    width={233}
    height={30}
  />
);
