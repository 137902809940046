import type { LogLevelDesc } from 'loglevel';
import getConfig from 'next/config';

import { util } from '@core/constant';
import {
  EDevice,
  EnvironmentPrivateApp,
  EnvironmentPublicApp,
  EVariableNameEnvironmentPrivate,
  EVariableNameEnvironmentPublic,
} from '@core/type';

enum EVariableNameEnvironmentNext {
  IS_PRODUCTION_BUILD = 'isProductionBuild',
  IS_PRODUCTION_SERVER = 'isProductionServer',
  IS_DEVELOPMENT_SERVER = 'isDevelopmentServer',
  HOST = 'host',
  CDN = 'cdn',
}

type EnvironmentPublic = {
  [EVariableNameEnvironmentNext.IS_PRODUCTION_BUILD]: boolean;
  [EVariableNameEnvironmentNext.IS_PRODUCTION_SERVER]: boolean;
  [EVariableNameEnvironmentNext.IS_DEVELOPMENT_SERVER]: boolean;
  [EVariableNameEnvironmentNext.HOST]: string;
  [EVariableNameEnvironmentNext.CDN]: string;
};

export type EnvironmentPublicNext = EnvironmentPublicApp<EnvironmentPublic>;

export const getEnvironmentPublic = (): EnvironmentPublicNext => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    publicRuntimeConfig,
  }: {
    publicRuntimeConfig: {
      [key in EVariableNameEnvironmentPublic | EVariableNameEnvironmentNext]: string;
    };
  } = getConfig();

  let staleTime = 0;
  if (publicRuntimeConfig.staleTimeClient && parseInt(publicRuntimeConfig.staleTimeClient) > 0) {
    // Transform from minutes to milliseconds
    staleTime = parseInt(publicRuntimeConfig.staleTimeClient) * util.STALETIME_COEF;
  }

  return {
    isProductionBuild: JSON.parse(publicRuntimeConfig.isProductionBuild || 'false') as boolean,
    isProductionServer: JSON.parse(publicRuntimeConfig.isProductionServer || 'false') as boolean,
    isDevelopmentServer: JSON.parse(publicRuntimeConfig.isDevelopmentServer || 'false') as boolean,
    host: publicRuntimeConfig.host || '',
    cdn: publicRuntimeConfig.cdn || '',
    cdnApi: publicRuntimeConfig.cdnApi || '',
    enableCdn: publicRuntimeConfig.enableCdn as unknown as boolean,
    enableBeforeUnload: publicRuntimeConfig.enableBeforeUnload as unknown as boolean,
    apiHost: publicRuntimeConfig.apiHost || '',
    wsHost: publicRuntimeConfig.wsHost || '',
    apiBase: publicRuntimeConfig.apiBase || '',
    apiVersion: publicRuntimeConfig.apiVersion || '',
    facebookPixelId: publicRuntimeConfig.facebookPixelId || '',
    logger: (publicRuntimeConfig.logger || 'silent') as LogLevelDesc,
    enableGoogleAnalytics: publicRuntimeConfig.enableGoogleAnalytics as unknown as boolean,
    enableMixpanel: publicRuntimeConfig.enableMixpanel as unknown as boolean,
    googleAnalyticsId: publicRuntimeConfig.googleAnalyticsId || '',
    mixpanelId: publicRuntimeConfig.mixpanelId || '',
    crispWebsiteId: publicRuntimeConfig.crispWebsiteId || '',
    cdnCsp: publicRuntimeConfig.cdnCsp || '',
    enableCrisp: publicRuntimeConfig.enableCrisp as unknown as boolean,
    enableTrustpilot: publicRuntimeConfig.enableTrustpilot as unknown as boolean,
    trustpilotApiKey: publicRuntimeConfig.trustpilotApiKey || '',
    enableSentry: publicRuntimeConfig.enableSentry as unknown as boolean,
    enableWebSocket: publicRuntimeConfig.enableWebSocket as unknown as boolean,
    enableVercelPreviewFeedback:
      publicRuntimeConfig.enableVercelPreviewFeedback as unknown as boolean,
    enableWebVitals: publicRuntimeConfig.enableWebVitals as unknown as boolean,
    env:
      (publicRuntimeConfig.env as 'development' | 'staging' | 'production' | 'preview') ||
      'development',
    sentryEnvelope: publicRuntimeConfig.sentryEnvelope || '',
    sentrySecurity: publicRuntimeConfig.sentrySecurity || '',
    enableYoutube: publicRuntimeConfig.enableYoutube as unknown as boolean,
    staleTimeClient: staleTime,
    enableRecaptcha: publicRuntimeConfig.enableRecaptcha as unknown as boolean,
    recaptchaKey: publicRuntimeConfig.recaptchaKey || '',
    googleApiKey: publicRuntimeConfig.googleApiKey || '',
    axeptioClientId: publicRuntimeConfig.axeptioClientId || '',
    saferpayUrl: publicRuntimeConfig.saferpayUrl || '',
    tokenFakeUser: publicRuntimeConfig.tokenFakeUser || '',
    enableAnalytics: publicRuntimeConfig.enableAnalytics as unknown as boolean,
    cdnImage: publicRuntimeConfig.cdnImage || '',
    cdnImageFolder: publicRuntimeConfig.cdnImageFolder || '',
    maintenanceMode: publicRuntimeConfig.maintenanceMode as unknown as boolean,
    enableSanityCdn: publicRuntimeConfig.enableSanityCdn as unknown as boolean,
    sanityId: publicRuntimeConfig.sanityId || '',
    sanityDataset: publicRuntimeConfig.sanityDataset || '',
    sanityVersion: publicRuntimeConfig.sanityVersion || '',
    sanityToken: publicRuntimeConfig.sanityToken || '',
    enableMobileAppPromotionBanner:
      publicRuntimeConfig.enableMobileAppPromotionBanner as unknown as boolean,
    enableNewHeader: publicRuntimeConfig.enableNewHeader as unknown as boolean,
    debugBearKey: publicRuntimeConfig.debugBearKey || '',
    enableDebugBear: publicRuntimeConfig.enableDebugBear as unknown as boolean,
  };
};

export type EnvironmentPrivateNext = EnvironmentPrivateApp<unknown>;

export const getEnvironmentPrivate = (): EnvironmentPrivateNext => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    serverRuntimeConfig,
  }: { serverRuntimeConfig: { [key in EVariableNameEnvironmentPrivate]: string } } = getConfig();

  let staleTimeServer = 0;
  if (serverRuntimeConfig.staleTimeServer && parseInt(serverRuntimeConfig.staleTimeServer) > 0) {
    // Transform from minutes to milliseconds
    staleTimeServer = parseInt(serverRuntimeConfig.staleTimeServer) * util.STALETIME_COEF;
  }

  let cacheTimeServer = 0;
  if (serverRuntimeConfig.cacheTimeServer && parseInt(serverRuntimeConfig.cacheTimeServer) > 0) {
    // Transform from minutes to milliseconds
    cacheTimeServer = parseInt(serverRuntimeConfig.cacheTimeServer) * util.STALETIME_COEF;
  }

  return {
    publicKey: serverRuntimeConfig.publicKey || '',
    staleTimeServer,
    cacheTimeServer,
    ipdataKey: serverRuntimeConfig.ipdataKey || '',
    saferpayTerminalId: serverRuntimeConfig.saferpayTerminalId || '',
    tokenAuthorizationApi: serverRuntimeConfig.tokenAuthorizationApi || '',
    deviceType: (serverRuntimeConfig.deviceType as unknown as EDevice) || EDevice.WEB,
    saferpayCustomerId: serverRuntimeConfig.saferpayCustomerId || '',
    saferpayToken: serverRuntimeConfig.saferpayToken || '',
  };
};
