import Image, { StaticImageData } from 'next/image';

import Icon2StepAuth2Svg from './svgs/icon-2-step-auth2.svg';

export const Icon2StepAuth2 = () => (
  <Image
    src={Icon2StepAuth2Svg as StaticImageData}
    alt="2 step authentification 2nd step icon"
    width={56}
    height={57}
  />
);
