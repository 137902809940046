import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import { LinkLiveChat } from '@core/component';
import { Translate } from '@core/constant';

import { ErrorDefaultView } from './ErrorDefaultView';

const ERROR_CODE = 404;

export const ViewNotFound = () => {
  const { t: tNotFound } = useTranslation(Translate.page.NOT_FOUND);

  return (
    <ErrorDefaultView
      error={ERROR_CODE}
      title={tNotFound('title')}
      subtitle={
        <Trans
          i18nKey={`${Translate.page.NOT_FOUND}:subtitle`}
          components={{ link: <LinkLiveChat text={tNotFound('openLiveChat')} /> }}
        />
      }
    />
  );
};
