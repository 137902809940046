import { LinkTranslatedStyledAsLink } from '@core/component';
import { metalPriceToSlug } from '@core/constant';
import { useContextCurrency } from '@core/context';
import { useLanguage } from '@core/hook';
import { EColor, ERouting, ETypographyVariant } from '@core/type';

import { LiveChartsMetalLinkProps } from '../interface-header';

export const LiveChartsMetalLink = ({ metal, children }: LiveChartsMetalLinkProps) => {
  const { currency } = useContextCurrency();
  const { language } = useLanguage();

  return (
    <LinkTranslatedStyledAsLink
      keyRoute={ERouting.CHARTS}
      query={{
        metalSlug: metalPriceToSlug[language][metal],
        slug: [currency.toLowerCase()],
      }}
      variant={ETypographyVariant.CAPTION3}
      color={EColor.ACCENT}
      language={language}
      isUnderlinedHover={false}
    >
      {children}
    </LinkTranslatedStyledAsLink>
  );
};
