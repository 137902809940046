import useTranslation from 'next-translate/useTranslation';

import { Button } from '@core/component';
import { Translate } from '@core/constant';
import { useContextRouting } from '@core/context';
import { EButtonVariant, EColor, EIcon, EPosition, ERouting, ESize } from '@core/type';

import { CloseDrawerFnTyp } from '../../../interface-header';

export const ButtonSignIn = ({ closeDrawer }: CloseDrawerFnTyp) => {
  const { setRoute } = useContextRouting();
  const { t: tHeader } = useTranslation(Translate.layout.HEADER);

  return (
    <Button
      onClick={() => {
        closeDrawer();
        setRoute(ERouting.SIGN_IN);
      }}
      variant={EButtonVariant.CONTAINED}
      color={EColor.SECONDARY}
      lineHeight={'40px'}
      endIcon={EIcon.ARROW_DOWN}
      iconOrientation={EPosition.RIGHT}
      iconSize={ESize.SM}
      isIconStroke
      isIconFilled
    >
      {tHeader('menu.signIn')}
    </Button>
  );
};
