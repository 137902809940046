import { useCallback, useState } from 'react';

import { Box, Drawer, Icon } from '@core/component';
import { EDrawerLevel } from '@core/component/interface';
import { useBodyStyle } from '@core/hook';
import { EActionAttribute, EColor, EIcon, EPosition, ESize } from '@core/type';

import { SideNavBlog } from '../../../SideNavBlog';
import { SideNavProps } from '../../../interface-header';
import { SideNavDefault } from './side-nav-default/SideNavDefault';

export const SideNav = ({
  shouldShowCurrencyLink,
  position = EPosition.LEFT,
  iconOrientation,
  iconWidth,
  iconColor,
  isBlogPage,
  menus,
}: SideNavProps) => {
  const defaultMenus: Partial<Record<EDrawerLevel, boolean>> = {
    [EDrawerLevel.DRAWER]: false,
  };
  if (menus) {
    menus.forEach((menu) => {
      defaultMenus[`/${menu.link.text}` as EDrawerLevel] = false;
    });
  }

  const [drawerLevelProps, setDrawerLevelProps] =
    useState<Partial<Record<EDrawerLevel | `${EDrawerLevel}/${string}`, boolean>>>(defaultMenus);
  const { removePosition } = useBodyStyle();

  const closeDrawer = useCallback(() => {
    removePosition();
    setDrawerLevelProps(defaultMenus);
  }, [removePosition]);

  const handleDrawerLevelProps = (props: Partial<Record<EDrawerLevel, boolean>>) => {
    setDrawerLevelProps((prevState) => ({ ...prevState, ...props }));
  };

  return (
    <Drawer
      position={position}
      iconOrientation={iconOrientation}
      iconWidth={iconWidth}
      iconColor={iconColor}
      drawerLevel={EDrawerLevel.DRAWER}
      drawerLevelProps={drawerLevelProps}
      size="100%"
      handleDrawerLevelProps={(props) =>
        setDrawerLevelProps((prevState) => ({ ...prevState, ...props }))
      }
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        padding={'4px 20px 30px'}
        height={'88px'}
        justifyContent={isBlogPage ? 'flex-end' : 'flex-start'}
      >
        <Icon
          display={'inline-flex'}
          type={EIcon.CROSS}
          size={ESize.SM}
          color={EColor.SECONDARY}
          onClick={closeDrawer}
        />
      </Box>
      <Box as={EActionAttribute.UL} position="relative" width="100%" listStyleType="none">
        {isBlogPage ? (
          <SideNavBlog
            position={position}
            closeDrawer={closeDrawer}
            shouldShowCurrencyLink={shouldShowCurrencyLink}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={handleDrawerLevelProps}
            isBlogPage
          />
        ) : (
          <SideNavDefault
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={handleDrawerLevelProps}
            shouldShowCurrencyLink={shouldShowCurrencyLink}
            closeDrawer={closeDrawer}
            menus={menus}
          />
        )}
      </Box>
    </Drawer>
  );
};

SideNav.displayName = 'SideNav';
