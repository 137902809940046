import { Language, typeRoute } from '@core/constant';
import { Logger } from '@core/logger';
import {
  DefaultRoute,
  ECurrency,
  ELanguage,
  ELanguageTags,
  ERouting,
  ERoutingDynamic,
  LinkNext,
  PathTranslationsType,
  TypeRoute,
} from '@core/type';

import { getLanguageFromTags, getLanguageTags } from './language';
import { addParameters } from './url';

const regexParam = /\[(.*?)\]/g;

export const handleDynamicUrl = (routeRaw: string, query: NodeJS.Dict<string | string[]>) => {
  if (!query) {
    return { route: routeRaw, queryParamsForAsParameter: null, queryParams: null };
  }

  const queryParams: NodeJS.Dict<string | string[]> = {};
  const queryParamsForAsParameter: NodeJS.Dict<string> = {};
  for (const [key, value] of Object.entries(query)) {
    if (Array.isArray(value)) {
      queryParamsForAsParameter[key] = value.join('/');
    } else {
      queryParamsForAsParameter[key] = value;
    }
    queryParams[key] = value;
  }

  let route = routeRaw;
  const matches = route?.match(regexParam);
  if (matches && typeof query !== 'string') {
    matches.forEach((match) => {
      const key = match.replace('[', '').replace(']', '').replace('*', '');

      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      route = route.replace(match, `${queryParamsForAsParameter[key]}`).replaceAll(/\((.*)\)/g, '');
      delete queryParamsForAsParameter[key];
      delete queryParams[key];
    });
  }

  return { route, queryParamsForAsParameter, queryParams };
};

export const isRouteWithQueryParam = (href: string): boolean => {
  return href?.includes('?') && href?.includes('=');
};

export const getDefaultLanguageSSR = (
  language: ELanguageTags,
): Exclude<ELanguageTags, 'default'> =>
  language === ELanguageTags.DEFAULT ? Language.I18N_DEFAULT_LANGUAGE : language;

export const getFullUrl = (
  host: string,
  lng: Exclude<ELanguageTags, ELanguageTags.DEFAULT>,
  keyRoute: ERouting,
  pathTranslations: PathTranslationsType,
  query?: NodeJS.Dict<string | string[]>,
): string => {
  if (keyRoute === ERouting.HOME) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return addParameters(`${host}/${getLanguageFromTags(lng)}`, Object(query));
  }

  const baseUrl = `${host}/${getLanguageFromTags(lng)}${getTranslatedRoute(
    keyRoute,
    lng,
    pathTranslations,
  )}`;
  const { route, queryParams } = handleDynamicUrl(baseUrl, query);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return addParameters(route, Object(queryParams));
};

export const getTranslatedRoute = (
  key: ERouting,
  language: Exclude<ELanguageTags, 'default'>,
  pathTranslations: PathTranslationsType,
): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  return pathTranslations[getDefaultLanguageSSR(language)][key];
};

export const getKey = (obj: DefaultRoute, route: string): ERouting => {
  if (route?.includes('?')) {
    return Object.keys(obj).find((key) => obj[key as ERouting] === route.split('?')[0]) as ERouting;
  }

  return Object.keys(obj).find((key) => obj[key as ERouting] === route) as ERouting;
};

export const getDefaultKey = (route: string, defaultRoute: DefaultRoute): ERouting => {
  return Object.keys(defaultRoute).find(
    (key) => defaultRoute[key as ERouting] === replaceDynamicSlug(route),
  ) as ERouting;
};

const replaceDynamicSlug = (route: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  return route?.replace(/\[(\.\.\.([^\]]+))\]/g, (_, match) => `[${match.replace(/\.\.\./, '')}*]`);
};

export const getRouteFromDefault = (
  route: string,
  language: Exclude<ELanguageTags, 'default'>,
  pathTranslations: PathTranslationsType,
  defaultRoute: DefaultRoute,
): string => {
  return getTranslatedRoute(getDefaultKey(route, defaultRoute), language, pathTranslations);
};

export const getQueries = (): NodeJS.Dict<string | string[]> => {
  const href = window?.location?.href.split('?')[1];
  if (href) {
    const queriesHref = href.split('&');
    const obj: NodeJS.Dict<string | string[]> = {};
    queriesHref.forEach((queryHref) => {
      obj[queryHref.split('=')[0]] = decodeURI(queryHref.split('=')[1]).replaceAll('+', ' ');
    });
    return obj;
  }
  return null;
};

export const getLinkNext = (
  key: ERouting,
  language: ELanguageTags,
  pathTranslations: PathTranslationsType,
  defaultRoute: DefaultRoute,
  query?: NodeJS.Dict<string | string[]>,
): LinkNext => {
  const route = getTranslatedRoute(key, getDefaultLanguageSSR(language), pathTranslations);

  if (key === ERouting.HOME) {
    return { href: defaultRoute[key], as: route };
  }

  const {
    route: routeHandled,
    queryParams,
    queryParamsForAsParameter,
  } = handleDynamicUrl(route, query);

  return {
    href: query ? { query: queryParams, pathname: routeHandled } : defaultRoute[key],
    as:
      queryParamsForAsParameter && Object.keys(queryParamsForAsParameter).length !== 0
        ? { query: queryParamsForAsParameter, pathname: routeHandled }
        : routeHandled,
  };
};

export const getTypeRoute = (
  key: ERouting,
  query?: Record<string, string | string[]>,
): TypeRoute => {
  const res = typeRoute[key] ?? typeRoute[ERouting.NOT_FOUND];

  if (key === ERouting.CHARTS) {
    const urlCurrency = query?.slug?.[0]?.toUpperCase() as ECurrency;
    if (Object.values(ECurrency).includes(urlCurrency)) {
      res.header.shouldShowCurrencyLink = true;
    }
  }

  return res;
};

export const getERoutingFromDynamicRouting = (dynamicRoute: ERoutingDynamic): ERouting => {
  const dynamicRoutes = Object.entries(ERoutingDynamic);
  const [dynamicKey] = dynamicRoutes.find(
    ([, currentRoute]) => currentRoute === dynamicRoute,
  ) as (keyof typeof ERouting)[];

  return ERouting[dynamicKey] ?? null;
};

export const redirect = async (
  keyRoute: ERouting,
  pathTranslations: PathTranslationsType,
  defaultRoute: DefaultRoute,
  fn: (link: LinkNext, keyRoute: ERouting, language?: ELanguage) => Promise<boolean>,
  language?: ELanguage,
  query?: NodeJS.Dict<string | string[]>,
): Promise<boolean> => {
  const link = getLinkNext(
    keyRoute,
    getLanguageTags(language),
    pathTranslations,
    defaultRoute,
    query,
  );
  if (link?.href) {
    Logger.logInfo(
      `Call redirect <{href:${JSON.stringify(link.href)},as:${JSON.stringify(
        link.as,
      )}, language:${language}}>`,
      { language: getLanguageTags(language), route: keyRoute, query },
    );

    return fn(link, keyRoute, language);
  }
  return null;
};

export const getCurrencyLinkHref = (fullUrl: string, currency: ECurrency) => {
  const tokens = fullUrl.split('/');
  tokens[tokens.length - 1] = currency.toLowerCase();

  return tokens.join('/');
};
