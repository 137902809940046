export enum ERegExpFlag {
  GLOBAL = 'g',
  IGNORE_CASE = 'i',
}

export const replaceToBold = (selectedText: string, text: string) => {
  const reg = new RegExp(selectedText, `${ERegExpFlag.GLOBAL}${ERegExpFlag.IGNORE_CASE}`);

  return text.replace(reg, '<strong>$&</strong>');
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

export const isStringWithNumber = (value: string) => {
  return /^\d*\.?\d*$/.test(value);
};
