import Image, { StaticImageData } from 'next/image';

import IconGaLogoAlertSvg from './svgs/icon-ga-logo-alert.svg';

export const IconGaLogoAlert = () => (
  <Image
    src={IconGaLogoAlertSvg as StaticImageData}
    alt="GA logo alert icon"
    width={32}
    height={32}
  />
);
