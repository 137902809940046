import { useSelectOptions } from 'core-widget/src/select/hooks';
import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';

import {
  Box,
  ButtonRadioBlueBordered,
  DrawerSub,
  IconCountry,
  LinkTranslatedStyledAsLink,
  Typography,
} from '@core/component';
import { DrawerCloseProps, EDrawerLevel } from '@core/component/interface';
import { Translate } from '@core/constant';
import { useContextAlternate, useContextRouting } from '@core/context';
import { useLanguage } from '@core/hook';
import { EActionAttribute, EColor, EFontWeight, ELanguageTags, EPosition } from '@core/type';
import { getCountryFromLanguage, getFullUrl, getLanguageFromTags } from '@core/util';

import { LanguageDrawerLineProps } from '../../../../interface-header';

const LanguageDrawerLineContainer = ({
  position = EPosition.LEFT,
  closeDrawer,
  drawerLevelProps,
  handleDrawerLevelProps,
  isBlogPage,
}: DrawerCloseProps & LanguageDrawerLineProps) => {
  const { language, changeLanguage } = useLanguage();
  const { languageOptions } = useSelectOptions();
  const { alternates } = useContextAlternate();
  const { pathTranslations } = useContextRouting();

  const { t } = useTranslation(Translate.common.LANGUAGE);

  return (
    <Box as={EActionAttribute.LI} padding="8px 40px 8px 40px">
      <DrawerSub
        closeDrawer={closeDrawer}
        drawerLevel={EDrawerLevel.LANGUAGE}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
        position={position}
        title={t('language')}
        size="100%"
        listTitle={
          <Box display={'flex'} alignItems={'center'}>
            <IconCountry
              height={14}
              width={14}
              country={getCountryFromLanguage(getLanguageFromTags(language))}
            />
            <Box marginLeft={'10px'}>
              <Typography whiteSpace={'nowrap'}>
                {getLanguageFromTags(language).toUpperCase()}
              </Typography>
            </Box>
          </Box>
        }
        isBlogPage={isBlogPage}
      >
        <Box display={'flex'} flexDirection={'column'} gap={'16px'} padding="8px 16px">
          {languageOptions.map(({ value, label }) => {
            const alternate = alternates?.find(
              ({ language: alternateLanguage }) => alternateLanguage === (value as ELanguageTags),
            );

            const alternateHrl = alternate
              ? getFullUrl(
                  '',
                  alternate.language,
                  alternate.keyRoute,
                  pathTranslations,
                  alternate.query,
                )
              : null;

            return alternateHrl ? (
              <LinkTranslatedStyledAsLink
                href={alternateHrl}
                locale={false}
                onClick={() => {
                  handleDrawerLevelProps({ [EDrawerLevel.LANGUAGE]: false });
                }}
              >
                <ButtonRadioBlueBordered
                  key={value}
                  value={value}
                  currentValue={language}
                  wrapperProps={{
                    minHeight: '60px',
                    justifyContent: 'space-between',
                    gap: '8px',
                  }}
                  labelWrapperProps={{
                    fontWeight: EFontWeight.REGULAR,
                    paddingLeft: '8px',
                    color: EColor.PRIMARY,
                  }}
                  inputBorderProps={{
                    borderRadius: '10px',
                  }}
                  isCircleAtTheEnd
                >
                  {label}
                </ButtonRadioBlueBordered>
              </LinkTranslatedStyledAsLink>
            ) : (
              <ButtonRadioBlueBordered
                key={value}
                value={value}
                onChange={() => {
                  changeLanguage(value as ELanguageTags);
                  handleDrawerLevelProps({ [EDrawerLevel.LANGUAGE]: false });
                }}
                currentValue={language}
                wrapperProps={{
                  minHeight: '60px',
                  justifyContent: 'space-between',
                  gap: '8px',
                }}
                labelWrapperProps={{
                  fontWeight: EFontWeight.REGULAR,
                  paddingLeft: '8px',
                  color: EColor.PRIMARY,
                }}
                inputBorderProps={{
                  borderRadius: '10px',
                }}
                isCircleAtTheEnd
              >
                {label}
              </ButtonRadioBlueBordered>
            );
          })}
        </Box>
      </DrawerSub>
    </Box>
  );
};

export const LanguageDrawerLine = memo(LanguageDrawerLineContainer);
