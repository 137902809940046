import useTranslation from 'next-translate/useTranslation';

import { Box } from '@core/component';
import { Translate } from '@core/constant';
import { EColor } from '@core/type';

export const LabelNew = () => {
  const { t: tFooter } = useTranslation(Translate.layout.FOOTER);

  return (
    <Box
      display={'inline-block'}
      padding={'0 4px'}
      marginLeft={'6px'}
      borderRadius={'8px'}
      color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
      fontSize={'10px'}
      lineHeight={'15px'}
      textTransform={'uppercase'}
      backgroundColor={EColor.ACCENT}
    >
      {tFooter('new')}
    </Box>
  );
};
