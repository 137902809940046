import styled, { css, CSSObject } from 'styled-components';

import { ColorProps, EColor, WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { Box } from '../layout/Box';
import { InputBorderProps } from './interface-input';

const getBorderColorType = ({
  isFocus,
  isError,
  isSuccess,
  isDisabled,
  isReadonly,
}: InputBorderProps): ColorProps => {
  if (isFocus && !isDisabled && !isReadonly) {
    return EColor.SECONDARY;
  } else if (isError) {
    return EColor.DANGER;
  } else if (isSuccess) {
    return EColor.SUCCESS;
  } else {
    return EColor.NEUTRAL;
  }
};

export const InputBorder = styled(Box)<InputBorderProps>(({
  theme: {
    palette,
    structure: {
      input: { border },
    },
  },
  isFocus,
  isError,
  isSuccess,
  isDisabled,
  isReadonly,
  borderRadius,
  backgroundColor,
  borderColor,
  zIndex,
}: InputBorderProps & WithThemeProps) => {
  const { getColor, getBackgroundColor } = getPaletteHandlers(palette);
  const borderColorType = getBorderColorType({
    isFocus,
    isError,
    isSuccess,
    isDisabled,
    isReadonly,
  });

  const rules = {
    ...border,
    zIndex: zIndex || border.zIndex,
    borderRadius: borderRadius || border.borderRadius,
    borderColor: getColor(borderColor || borderColorType),
    backgroundColor: backgroundColor ? getBackgroundColor(backgroundColor) : undefined,
  } as CSSObject;

  return css`
    ${rules};

    &:focus-within {
      outline: none;
    }
  `;
});
