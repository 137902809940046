import Image, { StaticImageData } from 'next/image';

import Icon2StepAuth3Svg from './svgs/icon-2-step-auth3.svg';

export const Icon2StepAuth3 = () => (
  <Image
    src={Icon2StepAuth3Svg as StaticImageData}
    alt="2 step authentification 3rd step icon"
    width={57}
    height={56}
  />
);
