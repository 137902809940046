import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';

import { Box, Line, LinkTranslatedStyledAsLink } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery, useContextRouting } from '@core/context';
import { EColor, EFontWeight, EIcon, ERouting, ESize } from '@core/type';

import { BlogSearchBar } from '../../view/blog/components/BlogSearchBar';
import { HeaderMenuBlogProps } from './interface-header';

const HeaderMenuBlogContainer = ({ blogCategories }: HeaderMenuBlogProps) => {
  const { isMobile, isDesktop } = useContextMediaQuery();
  const { query, keyRoute } = useContextRouting();
  const { t: tHeader } = useTranslation(Translate.layout.HEADER);

  const isHomePage = keyRoute === ERouting.BLOG_HOME;

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      textAlign={'center'}
      padding={isMobile ? '0 0 22px' : '14px 0'}
      flexDirection={isDesktop ? 'row' : 'column'}
      gap={'15px'}
    >
      {!isMobile && (
        <Box
          display={'flex'}
          alignItems={'center'}
          flexWrap={'wrap'}
          columnGap={'24px'}
          maxHeight={'26px'}
          overflow={'hidden'}
        >
          <LinkTranslatedStyledAsLink
            keyRoute={ERouting.BLOG_HOME}
            startIcon={EIcon.HOME}
            iconSize={ESize.MD}
            display={'flex'}
            iconMargin={8}
            fontSize={'15px'}
            fontWeight={EFontWeight.MEDIUM}
            color={isHomePage ? EColor.PRIMARY : EColor.ACCENT}
            iconColor={isHomePage ? EColor.PRIMARY : EColor.ACCENT}
            iconTop={'0'}
          >
            {tHeader('blog.allArticles')}
          </LinkTranslatedStyledAsLink>

          <Line
            isVertical
            margin={'0'}
            height={'14px'}
            color={{ commonType: EColor.GRAY, intensity: EColor.A400 }}
          />

          {blogCategories?.length > 0 &&
            blogCategories.map(({ title, slug }) => (
              <LinkTranslatedStyledAsLink
                key={slug}
                keyRoute={ERouting.BLOG_CATEGORY}
                query={{ slug }}
                fontSize={'15px'}
                color={query?.slug === slug ? EColor.PRIMARY : EColor.ACCENT}
                fontWeight={EFontWeight.MEDIUM}
              >
                {title}
              </LinkTranslatedStyledAsLink>
            ))}
        </Box>
      )}

      <BlogSearchBar />
    </Box>
  );
};

export const HeaderMenuBlog = memo(HeaderMenuBlogContainer);
