import { MutableRefObject, Ref as ReactRef } from 'react';

export type ArrayLengthMutationKeys = 'splice' | 'push' | 'pop' | 'shift' | 'unshift' | number;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ArrayItems<T extends Array<any>> = T extends Array<infer TItems> ? TItems : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FixedLengthArray<T extends any[]> = Pick<
  T,
  Exclude<keyof T, ArrayLengthMutationKeys>
> & { [Symbol.iterator]: () => IterableIterator<ArrayItems<T>> };

type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;

// Range F include to T exclude
// Example: Value X = IntRange<F, T> => (F <= X > T)
export type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;

type RefElementProps = {
  getBoundingClientRect: () => DOMRect;
  clientHeight: number;
  clientLeft: number;
  clientTop: number;
  clientWidth: number;
  offsetHeight: number;
  offsetWidth: number;
  offsetTop: number;
  offsetLeft: number;
  scrollHeight: number;
  parentElement: RefElementProps;
};

export type RefExtended<T, P extends object = object> = ReactRef<T> &
  MutableRefObject<RefElementProps & P>;

export type WithRef<T> = { ref: ReactRef<T> };

export type WIthInnerHTMLProps = {
  dangerouslySetInnerHTML: {
    __html: string;
  };
};

export type SetDifference<A, B> = A extends B ? never : A;
export type SetComplement<A, A1 extends A> = SetDifference<A, A1>;
export type Subtract<T extends T1, T1 extends object> = Pick<T, SetComplement<keyof T, keyof T1>>;

export enum ESortQuery {
  DEFAULT = 'default',
  ASC = 'asc',
  DESC = 'desc',
}

export enum EDevice {
  WEB = 'web',
  MOBILE = 'mobile',
}

export type SafeNumber = number | `${number}`;
