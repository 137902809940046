import useTranslation from 'next-translate/useTranslation';

import { Box, Icon, LinkTranslatedStyledAsLink, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { EColor, EIcon, EPosition, ERouting, ESize, ETypographyVariant } from '@core/type';

import { CloseDrawerFnTyp } from '../../../interface-header';

export const NeedHelp = ({ closeDrawer }: CloseDrawerFnTyp) => {
  const { t: tHeader } = useTranslation(Translate.layout.HEADER);

  return (
    <Box margin={'48px 0'} padding={'0 40px'}>
      <LinkTranslatedStyledAsLink keyRoute={ERouting.CONTACT} onClick={closeDrawer}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Box marginRight={'40px'}>
            <Typography variant={ETypographyVariant.H6} marginBottom={'8px'}>
              {tHeader('needHelp')}
            </Typography>
            <Typography>{tHeader('findYourAnswer')}</Typography>
          </Box>

          <Icon
            type={EIcon.ARROW_DOWN}
            orientation={EPosition.RIGHT}
            size={ESize.SM}
            flexShrink={'0'}
            color={EColor.ACCENT}
          />
        </Box>
      </LinkTranslatedStyledAsLink>
    </Box>
  );
};
