import useTranslation from 'next-translate/useTranslation';

import { Box, DrawerLine, Line } from '@core/component';
import { Translate } from '@core/constant';
import { useContextUser } from '@core/context';
import { useQueryApi } from '@core/hook';
import { EPosition, ERouting } from '@core/type';
import { BlogCategoriesResponse, EQueryKey } from '@core/type/api';

import { ButtonSignIn } from './components/header-default/button';
import { LanguageDrawerLine } from './components/header-default/header-default-mobile/side-nav-default';
import { HelloUser } from './components/header-default/header-default-mobile/side-nav-default/HelloUser';
import { SideNavBlogProps } from './interface-header';

export const SideNavBlog = ({
  position = EPosition.LEFT,
  closeDrawer,
  drawerLevelProps,
  handleDrawerLevelProps,
  isBlogPage,
}: SideNavBlogProps) => {
  const { data: blogCategories } = useQueryApi<BlogCategoriesResponse>(EQueryKey.BLOG_CATEGORIES);
  const { isLogged } = useContextUser();
  const { t: tHeader } = useTranslation(Translate.layout.HEADER);

  return (
    <>
      {isLogged && (
        <HelloUser
          closeDrawer={closeDrawer}
          handleDrawerLevelProps={handleDrawerLevelProps}
          drawerLevelProps={drawerLevelProps}
          isBlogPage={isBlogPage}
        />
      )}
      <DrawerLine
        title={tHeader('blog.whatIsGa')}
        route={ERouting.HOME}
        closeDrawer={closeDrawer}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={closeDrawer}
        isBlogPage={isBlogPage}
      />
      <Line margin="24px 20px" />
      <DrawerLine
        title={tHeader('blog.allArticles')}
        route={ERouting.BLOG_HOME}
        closeDrawer={closeDrawer}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={closeDrawer}
        isBlogPage={isBlogPage}
      />
      {blogCategories?.blogCategories
        ?.sort((a, b) => a.position - b.position)
        .map(({ slug, title }) => (
          <DrawerLine
            title={title}
            route={ERouting.BLOG_CATEGORY}
            query={{ slug }}
            closeDrawer={closeDrawer}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
            key={slug}
            isBlogPage={isBlogPage}
          />
        ))}

      <Box marginTop={'64px'}>
        <LanguageDrawerLine
          closeDrawer={closeDrawer}
          drawerLevelProps={drawerLevelProps}
          handleDrawerLevelProps={handleDrawerLevelProps}
          position={position}
          isBlogPage={isBlogPage}
        />
      </Box>

      {!isLogged && (
        <Box padding={'106px 40px 20px'}>
          <ButtonSignIn closeDrawer={closeDrawer} />
        </Box>
      )}
    </>
  );
};

SideNavBlog.displayName = 'SideNavBlog';
