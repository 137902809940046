import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import { Box, Image, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { EFilenameBlur, getStaticPathImage, getStaticPathImageBlurData } from '@core/media';
import { EActionAttribute, EColor, EFontWeight, ETypographyVariant } from '@core/type';

export const ViewMaintenance = () => {
  const { isPhone } = useContextMediaQuery();
  const { t } = useTranslation(Translate.page.MAINTENANCE);

  return (
    <Box
      backgroundColor={{ semanticType: EColor.PRIMARY, variant: EColor.DARK }}
      position={'absolute'}
      top={'0'}
      bottom={'0'}
      left={'0'}
      right={'0'}
      display={'flex'}
    >
      <Box
        display={'flex'}
        flexDirection={isPhone ? 'column' : 'row'}
        gap={isPhone ? '40px' : '80px'}
        margin={isPhone ? '88px auto 0' : 'auto'}
        padding={'0 32px'}
      >
        <Box
          width={isPhone ? '83px' : '118px'}
          height={isPhone ? '64px' : '104px'}
          position={'relative'}
        >
          <Image
            src={getStaticPathImage('maintenance/maintenance.png')}
            blurDataURL={getStaticPathImageBlurData(EFilenameBlur['maintenance/maintenance.png'])}
            alt={'maintenance mode'}
          />
        </Box>

        <Box color={{ commonType: EColor.BLUE, intensity: EColor.R100 }}>
          <Typography variant={ETypographyVariant.DISPLAY} marginBottom={'40px'}>
            <Trans i18nKey={`${Translate.page.MAINTENANCE}:title`} components={{ br: <br /> }} />
          </Typography>

          <Typography
            marginBottom={'8px'}
            variant={ETypographyVariant.H4}
            fontWeight={EFontWeight.REGULAR}
          >
            <Trans
              i18nKey={`${Translate.page.MAINTENANCE}:subtitle`}
              components={{
                strong: <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H4} />,
              }}
            />
          </Typography>

          <Typography variant={ETypographyVariant.H4} fontWeight={EFontWeight.REGULAR}>
            {t('thankYou')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
