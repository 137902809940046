import Image, { StaticImageData } from 'next/image';

import Icon2StepAuth2Svg from './svgs/ga-logo-with-background.svg';

export const IconGALogoWithBackground = () => (
  <Image
    src={Icon2StepAuth2Svg as StaticImageData}
    alt="GA logo with background"
    width={65}
    height={65}
  />
);
