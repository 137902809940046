import { EPosition, PositionType } from './position';
import { ESize, SizeType } from './size';

export type IconPosition = keyof PositionType<
  EPosition.TOP | EPosition.RIGHT | EPosition.LEFT | EPosition.BOTTOM | EPosition.CENTER
>;

export type IconSize = keyof SizeType<
  ESize.XXXS | ESize.XXS | ESize.XS | ESize.SM | ESize.MD | ESize.LG | ESize.XL | ESize.XXL
>;

export type IconSizeValues = {
  [key in IconSize]: string | number;
};

export enum EIcon {
  SPINNER = 'spinner',
  ALERT_ROUNDED = 'alertRounded',
  CART_REPEAT_ROUNDED = 'cartRepeatRounded',
  COPY = 'copy',
  CLOCK = 'clock',
  CLOCK_WITH_ARROW = 'clockWithArrow',
  INCREASE = 'increase',
  DEALS = 'deals',
  DECREASE = 'decrease',
  CROSS = 'cross',
  DISCOUNT = 'discount',
  BIN = 'bin',
  GA_LOGO = 'gaLogo',
  DELIVER = 'deliver',
  GA_LOGO_ALERT = 'gaLogoAlert',
  GA_LOGO_FULL = 'gaLogoFull',
  GA_LOGO_FULL_COLOR_FILLED = 'gaLogoFullColorFilled',
  GA_LOGO_WITH_PAMP = 'gaLogoWithPAMP',
  GA_LOGO_WITH_BACKGROUND = 'gaLogoWithBackground',
  CART = 'cart',
  CART_REPEAT = 'cartRepeat',
  CIRCULAR_ARROW = 'circularArrow',
  ALERT = 'alert',
  ALERT_FILLED = 'alertFilled',
  ARROW_RIGHT = 'arrowRight',
  ARROW_DOWN = 'arrowDown',
  ARROW_BIG = 'arrowBig',
  LOCK = 'lock',
  LOGO_60_YEARS = 'logo60Years',
  LOGO_SECURE = 'logoSecure',
  LOGO_SWISS = 'logoSwiss',
  LOGO_MAZARS = 'logoMazars',
  LOGO_LBMA = 'logoLBMA',
  MASTERCARD = 'mastercard',
  ANGLE = 'angle',
  ANGLE_DOUBLE = 'angleDouble',
  EYE = 'eye',
  EYE_CLOSED = 'eyeClosed',
  BANK_TRANSFER = 'bankTransfer',
  SEARCH = 'search',
  DRAWER = 'drawer',
  TRIANGLE = 'triangle',
  PAPER_PLANE = 'paperPlane',
  PLUS = 'plus',
  PLUS_ROUNDED = 'plusRounded',
  MINUS = 'minus',
  CHECK = 'check',
  PHONE = 'phone',
  PHONE2 = 'phone2',
  USER = 'user',
  CALENDAR = 'calendar',
  HEART = 'heart',
  HEART_BIG = 'heartBig',
  FILLED_HEART = 'filledHeart',
  PLAY = 'play',
  PLAY_CIRCLED = 'playCircled',
  PAUSE = 'pause',
  INFO = 'info',
  VISA = 'visa',
  UPS = 'ups',
  HELVETICOR = 'helveticor',
  LOOMIS = 'loomis',
  SWISS_POST = 'swissPost',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  LINKED_IN = 'linkedIn',
  LINKED_IN_SQUARE = 'linkedInSquare',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  TRUSTPILOT = 'trustpilot',
  CASE = 'case',
  SMARTPHONE_TEXT = 'smartphoneText',
  CIRCLE_PLUS = 'circlePlus',
  CHAT = 'chat',
  CHAT_WITH_TEXT = 'chatWithText',
  INCLUDED = 'included',
  SELL = 'sell',
  RECEIVE = 'receive',
  ARROW_LONG = 'arrowDownLong',
  DOWNLOAD = 'download',
  ADDRESS = 'address',
  SUCCESS = 'success',
  SUCCESS_FULL = 'successFull',
  PDF = 'pdf',
  HEAPS = 'heaps',
  ZERO_PERCENT = 'zeroPercent',
  THUMB_UP = 'thumbUp',
  REDEEM_PRODUCTS = 'redeemProducts',
  SWISS_SERVERS = 'swissServers',
  TWO_STEPS = 'wtoSteps',
  IP_CHECK = 'ipCheck',
  LIVE_PRICE = 'livePrice',
  TAG = 'tag',
  CIRCLE_CROSS = 'circleCross',
  SHIELD = 'shield',
  AUTH_2STEP_1 = 'Icon2StepAuth1',
  AUTH_2STEP_2 = 'Icon2StepAuth2',
  AUTH_2STEP_3 = 'Icon2StepAuth3',
  HELVETICOR_SECONDARY = 'helveticorSecondary',
  UNION = 'union',
  EUR = 'eur',
  CHF = 'chf',
  GBP = 'gbp',
  USD = 'usd',
  SMARTPHONE = 'smartphone',
  SMS = 'sms',
  MARKET_GRAPH = 'marketGraph',
  EDIT = 'edit',
  HOME = 'home',
  SMS_VALID = 'smsValid',
  GIFT = 'gift',
  SMARTPHONE_VALID = 'smartphoneValid',
  TAG_CROSS = 'tagCross',
  CIRCLE_ARROW = 'circleArrow',
  CONTACT = 'contact',
  EMAIL = 'email',
  DOCUMENT = 'document',
  FLEXIBLE_CHOICE = 'flexibleChoice',
  COIN_FOCUS = 'coinFocus',
  BEST_VALUE = 'bestValue',
  STAR = 'star',
  GRAPH = 'graph',
  WHATS_APP = 'whatsApp',
  ALERT_CIRCLE = 'alertCircle',
  ARROW_TURNED = 'arrowTurned',
  DELIVERY_VEHICLE = 'deliveryVehicle',
  APP_STORE = 'appStore',
  GOOGLE_PLAY = 'googlePlay',
  LINE_SOLID = 'lineSolid',
  LINE_DASHED = 'lineDashed',
  AUTO_SAVINGS = 'autoSavings',
  PAYMENT = 'payment',
  STORAGE_FEES = 'storageFees',
  SETTINGS = 'settings',
  CART_PURCHASING_LIMIT = 'cartPurchasingLimit',
  TRANSFER = 'transfer',
  FILTERS = 'filters',
  CALCULATOR = 'calculator',
}
