export enum EQueryKey {
  PAGE_SEARCH_PRODUCTS = 'pageSearchProducts',
  AUTO_SAVINGS_PRODUCTS = 'autoSavingsProducts',
  PAGE_AUTO_SAVINGS_PRODUCTS = 'pageAutoSavings',
  PAGE_HOME = 'pageHome',
  WIDGET_BLOG_ARTICLES = 'widgetBlogArticles',
  PAGE_BLOG_HOME = 'pageBlogHome',
  PAGE_STORAGE_SOLUTION = 'pageStorageSolution',
  PAGE_CONTACT_FAQ = 'pageContactFAQ',
  PAGE_HOME_FAQ = 'pageHomeFAQ',
  BANK_ACCOUNTS = 'bankAccounts',
  DEPOSIT_DETAILS = 'depositDetails',
  PURCHASING_LIMIT = 'purchasingLimit',
  DISCOUNT = 'discount',
  CART = 'cart',
  CMS = 'cms',
  SEARCH_ALL = 'searchAll',
  SEARCH_PRODUCTS = 'searchProducts',
  BLOG_ARTICLES = 'blogArticles',
  BLOG_CATEGORY = 'blogCategory',
  BLOG_CATEGORIES = 'blogCategories',
  BLOG_HIGHLIGHTED_ARTICLE = 'blogHighlightedArticle',
  BLOG_TAG = 'blogTag',
  PRODUCT = 'product',
  BLOG_SEARCH = 'blogSearch',
  BLOG_AUTOCOMPLETE_SEARCH = 'blogAutocompleteSearch',
  SPOT_PRICES = 'spotPrices',
  GET_GA_PAY_HISTORY = 'getGAPayHistory',
  GET_STORAGE_FEES_HISTORY = 'getStorageFeesHistory',
  BLOG_ARTICLE = 'blogArticle',
  FAQ_ARTICLE = 'faqArticle',
  GENERAL_CONDITIONS = 'generalConditions',
  IMPRESSUM = 'impressum',
  SPOT_PRICE_HISTORY = 'spotPriceHistory',
  SPOT_PRICE_CARATS = 'spotPriceCarats',
  SPOT_PRICE_CURRENCIES = 'spotPriceCurrencies',
  SPOT_PRICE_WEIGHTS = 'spotPriceWeights',
  PRIVACY_POLICY = 'privacyPolicy',
  MARKET_ALERTS = 'alerts',
  REFERRAL_PROGRAM = 'feferralProgram',
  COUNTRIES = 'countries',
  COUNTRIES_EN = 'countriesEn',
  TOTP = 'totp',
  DELIVERY_COUNTRIES = 'deliveryCountries',
  WISHLIST = 'wishlist',
  SHOP = 'shop',
  MOST_PURCHASED_PRODUCTS = 'mostPurchasedProducts',
  PRODUCT_SHOW = 'productShow',
  PRODUCT_ALERTS = 'productAlerts',
  SILVER_LANDING = 'silverLanding',
  STOCK_ALERTS = 'stockAlerts',
  TEAM_MEMBERS = 'teamMembers',
  KYC_QUESTION_LIST = 'kycQuestionList',
  USER_KYC_STEPS = 'userKycSteps',
  SHOP_SUBCATEGORY = 'shopSubcategory',
  TUTORIAL_VIDEOS = 'tutorialVideos',
  TUTORIAL_VIDEO = 'tutorialVideo',
  CURRENCY_RATES = 'currentRates',
  BANNER = 'banner',
  CUSTOMER_SURVEYS = 'customerSurveys',
  ONFIDO_SDK = 'onfido_sdk',
  UTILITY_BILLS = 'utilityBills',
  LIVE_CHARTS = 'liveCharts',
  FAQ_CATEGORIES = 'faqCategories',
  FAQ_CATEGORY = 'faqCategory',
  FAQ_SEARCH = 'faqSearch',
  FAQ_SEARCH_AUTOCOMPLETE = 'faqSearchAutocomplete',
  CERTIFICATE_DATES = 'certificateDate',
  PRECIOUS_METAL_GUIDE_ARTICLE = 'preciousMetalGuideArticle',
  PRECIOUS_METAL_GUIDE_MAIN_ARTICLE = 'preciousMetalGuideMainArticle',
  COMPONENT_ARTICLES = 'articles',
  COMPONENT_PRODUCTS = 'products',
  NEWSLETTER_SUBSCRIPTIONS = 'newsletterSubscriptions',
  NEWSLETTER_SUBSCRIPTIONS_WITH_TOKEN = 'newsletterSubscriptionsWithToken',
  COMPONENT_COLLECTIONS_BANNER = 'componentCollectionsBanner',
  COMPONENT_COLLECTIONS_PRODUCT = 'componentCollectionsProduct',
  WALLET = 'wallet',
  WALLET_AUTO_SAVINGS_PRODUCT = 'walletAutoSavingsProduct',
  WALLET_AUTO_SAVINGS_PRODUCTS = 'walletAutoSavingsProducts',
  WALLET_ORDERS = 'walletOrders',
  WALLET_ORDER_SHOW = 'walletOrderShow',
  WALLET_GIFTS = 'walletGifts',
  WALLET_GIFT_SHOW = 'walletGiftShow',
  WALLET_CLAIMS = 'walletClaims',
  WALLET_CLAIM_SHOW = 'walletClaimShow',
  WALLET_SALES = 'walletSales',
  WIDGET_PAGE_RATING = 'widgetPageRating',
  WIDGET_PRODUCT_GROUP = 'widgetProductGroup',
  WIDGET_RECENTLY_VIEWED_PRODUCTS = 'widgetRecentlyViewedProducts',
  CLAIM = 'claim',
  CLAIM_REVIEW = 'claimReview',
  CLAIM_HISTORY = 'claimHistory',
  GIFTS_RELATIONSHIPS = 'giftRelationships',
  GIFT_SHOW = 'giftShow',
  GIFT_USER_SHOW = 'giftUserShow',
  WAITING_GIFTS = 'waitingGifts',
  CREDIT_CARDS = 'creditCards',
  REFERRALS = 'referrals',
  STORAGE_FEES = 'storageFees',
  STORAGE_FEES_INVOICES = 'storageFeesInvoices',
  ORDER_HISTORY = 'orderHistory',
  FORECASTED_DELIVERY = 'forecastedDelivery',
  LAUNCHDARKLY = 'launchDarkly',
  INVESTMENT_CHARTS = 'investmentCharts',
}
