import Image, { StaticImageData } from 'next/image';

import SwissServerSvg from './svgs/swiss-server.svg';

export const IconSwissServers = () => (
  <Image
    src={SwissServerSvg as StaticImageData}
    alt="Swiss servers icon"
    width={140}
    height={28}
  ></Image>
);
