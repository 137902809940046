import Image, { StaticImageData } from 'next/image';

import IconRedeemProductsSvg from './svgs/icon-redeem-products.svg';

export const IconRedeemProducts = () => (
  <Image
    src={IconRedeemProductsSvg as StaticImageData}
    alt="Redeem products icon"
    width={97}
    height={96}
  />
);
