import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import NextLink from 'next/link';
import { memo, useMemo } from 'react';

import { Box, ButtonIcon, Line, LinkTranslatedStyledAsLink, Typography } from '@core/component';
import {
  GENERAL_CONDITIONS_MAP_SLUG_TO_URL,
  PRIVACY_POLICY_MAP_SLUG_TO_URL,
  Translate,
  YOUTUBE_URL,
  facebookUrl,
  instagramUrl,
  linkedinUrl,
  twitterUrl,
} from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useLanguage } from '@core/hook';
import { EActionAttribute, EColor, EIcon, ERouting, ESize, ETypographyVariant } from '@core/type';

import { SocialLinkProps } from './interface-footer';

const SocialLink = ({ type, href }: SocialLinkProps) => {
  const { t } = useTranslation(Translate.common.ARIA);

  return (
    <NextLink href={href} target={'_blank'}>
      <ButtonIcon
        as={EActionAttribute.SPAN}
        iconProps={{
          type,
          size: ESize.MD,
          color: { commonType: EColor.WHITE, intensity: EColor.R600 },
        }}
        hoverFill={{ commonType: EColor.WHITE, intensity: EColor.R700 }}
        size={ESize.MD}
        aria-label={t('weOnSocialMedia')}
      />
    </NextLink>
  );
};

const FooterBottomContainer = () => {
  const { isMobile } = useContextMediaQuery();
  const { language } = useLanguage();
  const { t: tFooter } = useTranslation(Translate.layout.FOOTER);

  const { facebook, twitter, linkedin, instagram } = useMemo(() => {
    return {
      facebook: facebookUrl[language],
      instagram: instagramUrl[language],
      linkedin: linkedinUrl[language],
      twitter: twitterUrl[language],
    };
  }, [language]);

  return (
    <Box
      padding={isMobile ? '20px 20px 24px' : '16px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      flexWrap={'wrap'}
      textAlign={'center'}
    >
      <Box display={'flex'} gap={'20px'} marginBottom={isMobile ? '8px' : '0'}>
        <SocialLink type={EIcon.YOUTUBE} href={YOUTUBE_URL} />
        <SocialLink type={EIcon.FACEBOOK} href={facebook} />
        <SocialLink type={EIcon.INSTAGRAM} href={instagram} />
        <SocialLink type={EIcon.LINKED_IN} href={linkedin} />
        <SocialLink type={EIcon.TWITTER} href={twitter} />
      </Box>
      {!isMobile && (
        <Line
          color={{ commonType: EColor.WHITE, intensity: EColor.R600 }}
          height={'8px'}
          width={'2px'}
          margin={'0 24px'}
          isVertical
        />
      )}
      <Box
        width={isMobile ? '100%' : 'auto'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        marginBottom={isMobile ? '8px' : '0'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Box lineHeight={'1'}>
          <LinkTranslatedStyledAsLink
            keyRoute={ERouting.PAGES}
            query={{ slug: GENERAL_CONDITIONS_MAP_SLUG_TO_URL[language] }}
            variant={ETypographyVariant.CAPTION3}
            color={EColor.GRAY}
          >
            {tFooter('menu.generalConditions.title')}
          </LinkTranslatedStyledAsLink>
        </Box>
        {!isMobile && (
          <Line
            color={{ commonType: EColor.WHITE, intensity: EColor.R600 }}
            height={'8px'}
            width={'2px'}
            margin={isMobile ? '0 16px' : '0 24px'}
            isVertical
          />
        )}
        <Box lineHeight={'1'}>
          <LinkTranslatedStyledAsLink
            keyRoute={ERouting.PAGES}
            query={{ slug: PRIVACY_POLICY_MAP_SLUG_TO_URL[language] }}
            variant={ETypographyVariant.CAPTION3}
            color={EColor.GRAY}
          >
            {tFooter('menu.privacyPolicy.title')}
          </LinkTranslatedStyledAsLink>
        </Box>
        {!isMobile && (
          <Line
            color={{ commonType: EColor.WHITE, intensity: EColor.R600 }}
            height={'8px'}
            width={'2px'}
            margin={isMobile ? '0 16px' : '0 24px'}
            isVertical
          />
        )}
        <Box lineHeight={'1'}>
          <LinkTranslatedStyledAsLink
            keyRoute={ERouting.IMPRESSUM}
            variant={ETypographyVariant.CAPTION3}
            color={EColor.GRAY}
          >
            {tFooter('menu.impressum.title')}
          </LinkTranslatedStyledAsLink>
        </Box>
      </Box>
      {!isMobile && (
        <Line
          color={{ commonType: EColor.WHITE, intensity: EColor.R600 }}
          height={'8px'}
          width={'2px'}
          margin={'0 24px'}
          isVertical
        />
      )}
      <Typography
        variant={ETypographyVariant.CAPTION3}
        color={{ commonType: EColor.WHITE, intensity: EColor.R600 }}
        lineHeight={'1'}
      >
        <Trans
          i18nKey={`${Translate.layout.FOOTER}:menu.rightsReserved.title`}
          values={{ years: `2018-${new Date().getFullYear()}` }}
        />
      </Typography>
    </Box>
  );
};

export const FooterBottom = memo(FooterBottomContainer);
