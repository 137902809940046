import Image, { StaticImageData } from 'next/image';

import IconSmartphoneTextSvg from './svgs/icon-smartphone-text.svg';

export const IconSmartphoneText = () => (
  <Image
    src={IconSmartphoneTextSvg as StaticImageData}
    alt="Smartphone icon"
    width={24}
    height={24}
  />
);
