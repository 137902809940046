import { ERouting } from '@core/type';

export const BLOG_ROUTES = [
  ERouting.BLOG_ARTICLE,
  ERouting.BLOG_CATEGORY,
  ERouting.BLOG_SEARCH,
  ERouting.BLOG_TAG,
  ERouting.BLOG_HOME,
];

export const NEWS_ARTICLE_ROUTES = [
  ERouting.BLOG_ARTICLE,
  ERouting.PRECIOUS_METAL_GUIDE,
  ERouting.PRECIOUS_METAL_GUIDE_LEVEL1,
  ERouting.PRECIOUS_METAL_GUIDE_LEVEL2,
  ERouting.PRECIOUS_METAL_GUIDE_LEVEL3,
];
