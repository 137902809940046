import { zIndexes } from '@core/constant';
import {
  EAccordion,
  ELink,
  ELinkAnimation,
  EPosition,
  ESize,
  EZIndexName,
  ThemeStructure,
} from '@core/type';

export const themeDefaultStructure: ThemeStructure = {
  pendingGift: {
    wrapper: {
      margin: '70px auto 35px',
    },
    descriptionText: {
      marginBottom: '35px',
    },
  },
  giftRecipient: {
    paper: {
      padding: '30px 100px',
    },
  },
  receiveLatestSpotlights: {
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    buttonTextWrapper: {
      flexDirection: 'row',
    },
  },
  charts: {
    livePriceDisplay: 'inline-block',
    headerBox: {
      padding: '40px 98px 248px',
      margin: '0 auto -216px',
    },
    chartPaper: { maxWidth: '946px', padding: '48px' },
  },
  modalContainer: {
    padding: '48px 40px',
    size: {
      [ESize.MD]: {
        maxWidth: '500px',
      },
      [ESize.LG]: {
        maxWidth: '634px',
      },
      [ESize.XL]: {
        maxWidth: '694px',
      },
      [ESize.XXL]: {
        maxWidth: '800px',
      },
    },
  },
  videoModalContainer: {
    height: 'auto',
    iconBox: {
      top: '-3px',
      right: '-29px',
    },
  },
  button: {
    base: {
      cursor: 'pointer',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '8px',
      textDecoration: 'none',
      transitionDuration: '.4s',
      transitionTimingFunction: 'easy-out',
      transitionProperty: 'color, background-color, border-color',
      whiteSpace: 'nowrap',
    },
    disabled: {
      cursor: 'not-allowed',
    },
    size: {
      [ESize.XS]: {
        padding: '0 15px',
      },
      [ESize.SM]: {
        padding: '0 10px',
      },
      [ESize.MD]: {
        padding: '0 15px',
      },
      [ESize.LG]: {
        padding: '0 20px',
      },
    },
    icon: {
      indent: '8px',
      size: ESize.MD,
      transitionDuration: '.4s',
      transitionTimingFunction: 'easy-out',
      transitionProperty: 'all',
    },
  },
  buttonIcon: {
    padding: '0',
    borderWidth: '0',
    borderRadius: '0',
    borderStyle: 'none',
  },
  input: {
    defaultSize: ESize.MD,
    base: {
      display: 'block',
      width: '100%',
      minHeight: '24px',
      outline: 'none',
      padding: '0',
      resize: 'none',
      boxShadow: 'none',
      borderRadius: '0',
      borderWidth: '0',
      borderStyle: 'none',
      boxSizing: 'border-box',
      active: {
        boxShadow: 'none',
        outline: 'none',
      },
      hidden: {
        position: 'absolute',
        top: '0',
        left: '0',
        opacity: '0',
        pointerEvents: 'none',
      },
    },
    label: {
      position: 'absolute',
      letterSpacing: '.4px',
      lineHeight: '18px',
      left: '17px',
      overflow: 'hidden',
      width: 'calc(100% - 30px)',
      paddingRight: '17px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      pointerEvents: 'none',
      transition:
        'transform .18s cubic-bezier(.4,0,.2,1),color .18s cubic-bezier(.4,0,.2,1),-webkit-transform .18s cubic-bezier(.4,0,.2,1)',
      transformOrigin: 'left top',
      required: {
        content: '"*"',
        display: 'inline-block',
        marginLeft: '4px',
      },
      active: {
        transform: 'translateY(-69%) scale(0.70)',
        width: 'calc((100% - 16px) / 0.7)',
      },
      size: {
        [ESize.MD]: {
          top: '17px',
        },
        [ESize.LG]: {
          top: '20px',
        },
      },
    },
    border: {
      position: 'absolute',
      zIndex: zIndexes[EZIndexName.AUTO],
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      borderRadius: '6px',
      borderWidth: '1px',
      borderStyle: 'solid',
      pointerEvents: 'none',
    },
    background: {
      position: 'absolute',
      zIndex: zIndexes[EZIndexName.BACKGROUND],
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
    },
    text: {
      wrapper: {
        position: 'relative',
        width: '100%',
        minHeight: '32px',
        padding: '0 16px',
        paddingWithIcon: '40px',
        paddingWithLabel: '18px 16px 6px',
        overflow: 'hidden',
        transition: 'all .1s',
        zIndex: 0,
        size: {
          [ESize.MD]: {
            height: '48px',
          },
          [ESize.LG]: {
            height: '56px',
          },
        },
      },
      base: {
        height: '100%',
        position: 'relative',
      },
    },
  },
  header: {
    short: { height: '72px', padding: '24px 0', animation: '56px' },
    maxWidth: '1170px',
    option: { flexDirection: 'row' },
    blog: { height: '134px', animation: '72px', padding: '18px 0' },
    height: '72px',
    animation: '50px',
    logoHeight: '32px',
    metals: { padding: '4px 16px' },
    minWidthSearchButton: '56px',
    padding: '24px 0',
    signUp: { height: '56px', padding: '24px 0 0' },
  },
  form: {
    label: {
      height: '32px',
    },
    marginBottom: '0.8em',
  },
  breadcrumb: {
    marginBottom: '24px',
    padding: '0 5px',
    yTranslation: '4px',
    sizeIcon: ESize.SM,
  },
  container: {
    margin: '0 auto',
    gutter: '20px',
    width: '100%',
    maxWidth: '1180px',
  },
  mainContainer: {
    width: '100%',
    padding: '24px 0 144px',
    fullHeightPagePadding: '24px 0',
    margin: '0 auto',
    minHeight: '462px',
  },
  grid: {
    container: {
      columns: 12,
      display: 'flex',
      flexWrap: 'wrap',
    },
    item: {
      rowSpacing: 0,
      columnSpacing: 0,
      gridColumns: 1,
    },
  },
  link: {
    base: {
      cursor: 'pointer',
      position: 'relative',
      borderStyle: 'none',
      textDecoration: 'none',
      backgroundColor: 'transparent',
      font: 'inherit',
      lineHeight: '1.2',
      padding: '0',
    },
    underlineFirstPath: {
      paddingBottom: '2px',
      backgroundSize: '0 1px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0 calc(1em + 3px)',
      backgroundImage: 'linear-gradient(to bottom, transparent 20%, currentColor 21%)',
    },
    underlineSecondPath: {
      backgroundSize: '100% 1px',
      transitionDelay: '0s',
    },
    icon: {
      base: {
        position: 'relative',
        top: '2px',
        right: '0',
        display: 'inline-block',
      },
      [ELinkAnimation.ARROW_RIGHT]: {
        right: '-8px',
      },
      [ELinkAnimation.ARROW_DOWN]: {
        top: '8px',
      },
    },
    svg: {
      transitionDelay: '0s',
    },
    text: {
      position: 'relative',
      whiteSpace: 'normal',
    },
    transition: 'all 0.2s ease-out 0.2s',
    underlined: 'background-size: 100% 1px',
    indent: '5px',
    variant: {
      [ELink.WITHOUT_TEXT_ON_MOBILE]: {
        display: 'inline',
      },
    },
  },
  paper: {
    border: {
      borderWidth: '1px',
      borderRadius: '8px',
      borderStyle: 'solid',
    },
    padding: '0',
    transition: 'box-shadow .3s',
    form: {
      padding: '48px',
      maxWidth: '552px',
      hasElevation: true,
      paddingTop: '16px',
    },
  },
  line: {
    vertical: {
      margin: '0 25px',
      height: '25px',
      width: '1px',
    },
    horizontal: {
      margin: '0',
      height: '1px',
      border: 'none',
    },
    isFullWidth: {
      base: {
        background: 'none',
      },
      before: {
        content: '""',
        position: 'absolute',
        left: '0',
        width: '100%',
        height: 'inherit',
      },
    },
  },
  label: {
    base: {
      display: 'inline-block',
      overflow: 'hidden',
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      textTransform: 'uppercase',
    },
    sizes: {
      [ESize.SM]: {
        padding: '0 4px',
        fontSize: '12px',
        lineHeight: '24px',
        borderRadius: '4px',
      },
      [ESize.MD]: {
        padding: '0 6px',
        fontSize: '14px',
        lineHeight: '28px',
        borderRadius: '6px',
      },
      [ESize.LG]: {
        padding: '0 12px',
        fontSize: '16px',
        lineHeight: '32px',
        borderRadius: '6px',
      },
    },
  },
  toast: {
    wrapper: {
      zIndex: zIndexes[EZIndexName.MODAL],
      maxWidth: '490px',
      position: 'fixed',
      width: 'auto',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      placement: {
        [EPosition.TOP_RIGHT]: {
          top: '150px',
          right: '56px',
          bottom: 'auto',
          left: 'auto',
        },
        [EPosition.BOTTOM_RIGHT]: {
          top: 'auto',
          right: '56px',
          bottom: '150px',
          left: 'auto',
        },
        [EPosition.TOP_LEFT]: {
          top: '150px',
          right: 'auto',
          bottom: 'auto',
          left: '56px',
        },
        [EPosition.BOTTOM_LEFT]: {
          top: 'auto',
          right: 'auto',
          bottom: '150px',
          left: '56px',
        },
      },
    },
    item: {
      position: 'relative',
      animationDuration: '1s',
      spacing: '12px',
      maxHeight: '300px',
      shift: {
        [EPosition.TOP_RIGHT]: 'calc(100% + 56px)',
        [EPosition.BOTTOM_RIGHT]: 'calc(100% + 56px)',
        [EPosition.TOP_LEFT]: 'calc(-100% - 56px)',
        [EPosition.BOTTOM_LEFT]: 'calc(-100% - 56px)',
      },
    },
  },
  cartPopup: {
    wrapper: {
      margin: '0',
      maxWidth: '358px',
    },
  },
  tooltip: {
    container: {
      position: 'relative',
      cursor: 'pointer',
    },
    body: {
      width: '430px',
      position: 'absolute',
      borderRadius: '8px',
      padding: '24px',
      zIndex: zIndexes[EZIndexName.TOOLTIP],
      cursor: 'auto',
      placement: {
        [EPosition.TOP]: {
          top: 0,
          bottom: 'auto',
          right: 'auto',
          transform: 'translate(0%, calc(-100% - 16px))',
        },
        [EPosition.RIGHT]: {
          top: 0,
          left: 'auto',
          bottom: 'auto',
          right: 0,
          transform: 'translate(calc(100% + 16px), 0%)',
        },
        [EPosition.BOTTOM]: {
          top: 'auto',
          bottom: 0,
          right: 'auto',
          transform: 'translate(0%, calc(100% + 16px))',
        },
        [EPosition.LEFT]: {
          top: 0,
          left: 0,
          bottom: 'auto',
          right: 'auto',
          transform: 'translate(calc(-100% - 16px), 0%)',
        },
      },
    },
    arrow: {
      position: 'absolute',
      zIndex: zIndexes[EZIndexName.TOOLTIP],
      content: '""',
      width: '16px',
      height: '16px',
      transform: 'rotate(45deg)',
      placement: {
        [EPosition.TOP]: {
          left: 'calc(50% - 8px)',
          top: '-24px',
          bottom: 'auto',
          right: 'auto',
        },
        [EPosition.RIGHT]: {
          left: 'calc(100% + 8px)',
          top: 'calc(50% - 8px)',
          bottom: 'auto',
          right: 'auto',
        },
        [EPosition.BOTTOM]: {
          left: 'calc(50% - 8px)',
          top: 'auto',
          bottom: '-24px',
          right: 'auto',
        },
        [EPosition.LEFT]: {
          top: 'calc(50% - 8px)',
          right: 'calc(100% + 8px)',
          bottom: 'auto',
          left: 'auto',
        },
      },
    },
  },
  footer: {
    subscribe: { height: '64px', padding: '0 24px', flexDirection: 'row' },
    menu: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '32px 40px 40px',
      optionsContainer: { padding: '0 30px 0 0' },
      column: { marginBottom: '10px' },
    },
  },
  content: {
    title: {
      fontSize: '32px',
    },
  },
  tab: {
    position: 'relative',
    display: 'block',
    padding: '20px 48px 12px',
    border: 'none',
    width: '100%',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    height: '64px',
    borderRadius: '8px 8px 0 0',
    transition: 'none',
    cursor: 'pointer',
    topBorder: {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '8px',
      borderRadius: '8px 8px 0 0',
    },
  },
  autocomplete: {
    inputWidth: '168px',
    autocompleteWidth: null,
  },
  table: {
    base: {
      marginTop: '-24px',
    },
    heading: {
      padding: '24px 0 12px',
    },
    row: {
      padding: '24px 0px',
    },
    grid: {
      columns: 24,
      rowSpacing: 16,
      columnSpacing: 16,
    },
  },
  productCard: {
    base: {
      position: 'relative',
      cursor: 'pointer',
      display: 'block',
      height: '408px',
      width: '100%',
      transition: 'z-index .5s',
    },
    link: {
      textDecoration: 'none',
    },
    content: {
      base: {
        padding: '24px',
        boxShadow: 'none',
        height: '100%',
        maxWidth: '283px',
        borderRadius: '8px',
        transition: 'box-shadow .5s, background .5s',
      },
    },
    labelsContainer: {
      height: '28px',
    },
    imageContainer: {
      width: '100%',
      height: '170px',
    },
    statusLabel: {
      base: {
        textAlign: 'center',
        letterSpacing: '.1px',
        borderRadius: '8px',
      },
      container: {
        position: 'absolute',
        left: '0px',
        top: '0px',
      },
      sizes: {
        [ESize.MD]: {
          padding: '8px 15px',
          minHeight: '30px',
          fontSize: '14px',
          lineHeight: '14px',
        },
        [ESize.LG]: {
          padding: '0 15px',
          minHeight: '32px',
          fontSize: '16px',
          lineHeight: '32px',
        },
      },
    },
    hoverEffect: {
      base: {
        opacity: '0',
        transition: 'all .4s ease-out',
      },
      hover: {
        opacity: '1',
      },
    },
  },
  accordion: {
    base: {
      [EAccordion.FAQ]: {
        borderRadius: '16px',
        padding: '24px 48px',
        margin: 0,
        transition: 'all 0.3s',
      },
    },
    body: {
      overflow: 'hidden',
      transition: 'height 0.35s ease, visibility 0.35s ease',
    },
  },
  discountBanner: {
    padding: '4px 0px',
  },
  select: {
    defaultSize: ESize.MD,
    wrapper: {
      size: {
        [ESize.XS]: {
          height: '36px',
        },
        [ESize.SM]: {
          height: '40px',
        },
        [ESize.MD]: {
          height: '48px',
        },
        [ESize.LG]: {
          height: '56px',
        },
        [ESize.XL]: {
          height: '68px',
        },
      },
    },
  },
};
