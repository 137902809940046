import styled, { css } from 'styled-components';

import { EColor, EIcon, ESize, WithThemeProps } from '@core/type';

import { ButtonIcon } from '../button/ButtonIcon';
import { Box } from '../layout/Box';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import { ModalStyled } from './ModalStyled';
import { ModalProps } from './interface-modal';

const Container = styled(Box)((props: WithThemeProps) => {
  const {
    theme: {
      structure: {
        modalContainer: { padding: modalPadding },
      },
    },
  } = props;

  return css`
    padding: ${modalPadding};
  `;
});

const Modal = ({
  isOpen,
  children,
  onCloseModal,
  size = ESize.MD,
  isFull,
  hasCloseButton = true,
  isClosedOnBGClick = true,
}: ModalProps) => {
  return (
    <ModalStyled
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      size={size}
      isFull={isFull}
      hasCloseButton={hasCloseButton}
      isClosedOnBGClick={isClosedOnBGClick}
    >
      {hasCloseButton && (
        <Box top="20px" right="20px" position="absolute" cursor="pointer">
          <ButtonIcon
            onClick={onCloseModal}
            padding="0"
            iconProps={{
              type: EIcon.CROSS,
              color: EColor.SECONDARY,
              strokeWidth: 1,
            }}
          />
        </Box>
      )}
      <Container>{children}</Container>
    </ModalStyled>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export { Modal };
