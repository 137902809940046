import useTranslation from 'next-translate/useTranslation';

import { Box, Line } from '@core/component';
import { DrawerCloseProps } from '@core/component/interface';
import { Translate } from '@core/constant';
import { useContextProfile, useContextUser } from '@core/context';
import { EIcon, ERouting } from '@core/type';

import { ButtonSignIn, ButtonSignOut } from '../../button';
import { DropdownLine } from './DropdownLine';
import { MyWallet } from './MyWallet';
import { ReferFriends } from './ReferFriends';

export const MobileDrawer = ({ closeDrawer }: DrawerCloseProps) => {
  const { hasNotCompletedStep, hasStorageFeesUnpaid } = useContextProfile();
  const { isLogged } = useContextUser();
  const { t: tHeader } = useTranslation(Translate.layout.HEADER);

  return (
    <>
      <MyWallet closeDrawer={closeDrawer} />
      <Line margin={'22px 20px'} />

      <Box padding={'0 40px'}>
        <DropdownLine
          icon={EIcon.AUTO_SAVINGS}
          title={tHeader('menu.autoSavings')}
          route={ERouting.WALLET_AUTO_SAVINGS}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.STORAGE_FEES}
          title={tHeader('menu.storageFees')}
          route={ERouting.STORAGE_FEES}
          hasError={hasStorageFeesUnpaid}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.PAYMENT}
          title={tHeader('menu.paymentMethods')}
          route={ERouting.PAYMENTS}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.CLOCK_WITH_ARROW}
          title={tHeader('menu.orderHistory')}
          route={ERouting.WALLET_ORDERS}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.ALERT_FILLED}
          title={tHeader('menu.alertsSettings')}
          route={ERouting.ALERT_SETTINGS}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.HEART_BIG}
          title={tHeader('menu.wishList')}
          route={ERouting.WISHLIST}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.CART_PURCHASING_LIMIT}
          title={tHeader('menu.purchasingLimit')}
          route={ERouting.PURCHASING_LIMIT}
          hasError={hasNotCompletedStep}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.SETTINGS}
          title={tHeader('menu.profileSettings')}
          route={ERouting.PROFILE}
          closeDrawer={closeDrawer}
        />
        <Line margin={'22px -20px 48px'} />
        <ReferFriends closeDrawer={closeDrawer} />
        {isLogged ? (
          <ButtonSignOut closeDrawer={closeDrawer} />
        ) : (
          <ButtonSignIn closeDrawer={closeDrawer} />
        )}
      </Box>
    </>
  );
};
