import { useSelectOptions } from 'core-widget/src/select/hooks';
import { memo } from 'react';

import {
  Box,
  ButtonRadioBlueBordered,
  DrawerSub,
  Icon,
  LinkTranslatedStyledAsLink,
  Typography,
} from '@core/component';
import { DrawerCloseProps, EDrawerLevel } from '@core/component/interface';
import { useContextCurrency, useContextRouting } from '@core/context';
import { useFullUrl } from '@core/hook';
import {
  EActionAttribute,
  EColor,
  ECurrency,
  EFontWeight,
  EIcon,
  EPosition,
  ERouting,
} from '@core/type';
import { getCurrencyLinkHref } from '@core/util';

import { ButtonRadioCurrencyProps } from './interface-side-nav';

const CurrencyDrawerLineContainer = ({
  closeDrawer,
  drawerLevelProps,
  handleDrawerLevelProps,
}: DrawerCloseProps) => {
  const { keyRoute } = useContextRouting();
  const { currency, changeCurrency } = useContextCurrency();
  const { currencyOptions } = useSelectOptions();

  const { fullUrl } = useFullUrl('');
  const isChartsPage = keyRoute === ERouting.CHARTS;

  return (
    <Box as={EActionAttribute.LI} padding="8px 40px 8px 40px">
      <DrawerSub
        closeDrawer={closeDrawer}
        drawerLevel={EDrawerLevel.CURRENCY}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
        position={EPosition.LEFT}
        title={currency.toUpperCase()}
        listTitle={
          <Box display={'flex'} alignItems={'center'}>
            <Icon type={currency.toLowerCase() as EIcon} flexShrink={'0'} />
            <Box marginLeft={'8px'}>
              <Typography whiteSpace={'nowrap'}>{currency.toUpperCase()}</Typography>
            </Box>
          </Box>
        }
        size="100%"
      >
        <Box display={'flex'} flexDirection={'column'} gap={'16px'} padding="8px 16px">
          {currencyOptions.map(({ value, label }) => {
            const href = getCurrencyLinkHref(fullUrl, value as ECurrency);

            return isChartsPage ? (
              <LinkTranslatedStyledAsLink
                key={value}
                href={href}
                locale={false}
                onClick={() => {
                  handleDrawerLevelProps({ [EDrawerLevel.CURRENCY]: false });
                }}
              >
                <ButtonRadioCurrency
                  value={value}
                  label={label}
                  currency={currency}
                  changeCurrency={changeCurrency}
                  handleDrawerLevelProps={handleDrawerLevelProps}
                />
              </LinkTranslatedStyledAsLink>
            ) : (
              <ButtonRadioCurrency
                key={value}
                value={value}
                label={label}
                currency={currency}
                changeCurrency={changeCurrency}
                handleDrawerLevelProps={handleDrawerLevelProps}
              />
            );
          })}
        </Box>
      </DrawerSub>
    </Box>
  );
};

const ButtonRadioCurrency = ({
  value,
  label,
  currency,
  changeCurrency,
  handleDrawerLevelProps,
}: Pick<DrawerCloseProps, 'handleDrawerLevelProps'> & ButtonRadioCurrencyProps) => {
  return (
    <ButtonRadioBlueBordered
      key={value}
      value={value}
      onChange={() => {
        changeCurrency(value as ECurrency);
        handleDrawerLevelProps({ [EDrawerLevel.CURRENCY]: false });
      }}
      currentValue={currency}
      wrapperProps={{
        minHeight: '60px',
        justifyContent: 'space-between',
        gap: '8px',
      }}
      labelWrapperProps={{
        fontWeight: EFontWeight.REGULAR,
        paddingLeft: '8px',
        color: EColor.PRIMARY,
      }}
      inputBorderProps={{
        borderRadius: '10px',
      }}
      isCircleAtTheEnd
    >
      {label}
    </ButtonRadioBlueBordered>
  );
};

export const CurrencyDrawerLine = memo(CurrencyDrawerLineContainer);
