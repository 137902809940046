import { useEventPageView } from '@google-tag-manager';
import { useEventMixpanelPageViewed } from '@mixpanel';
import { useSanityData } from '@web-app/hooks';
import { FooterWithLogos } from '@web-app/layout/footer';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, memo, useMemo, useState } from 'react';

import { Box, Button, Typography } from '@core/component';
import {
  EAccessRoute,
  EButtonVariant,
  EColor,
  ECurrency,
  EFooter,
  EHeader,
  ERouting,
  EZIndexName,
  TypeRoute,
} from '@core/type';
import {
  BlogCategory,
  SanityHeaderDefaultResponse,
  ShopSubcategoriesResponse,
} from '@core/type/api';

import { Footer } from './footer/Footer';
import { HeaderContainer } from './header/HeaderContainer';

type LayoutProps = {
  children: ReactNode;
  isPhone?: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
  typeRoute: TypeRoute;
  keyRoute: ERouting;
  query?: NodeJS.Dict<string | string[]>;
  blogCategories: BlogCategory[];
  subcategories?: ShopSubcategoriesResponse;
  headerMenus?: SanityHeaderDefaultResponse['menus'];
  isDraft?: boolean;
};

export const Layout = memo(
  ({
    children,
    typeRoute,
    isPhone,
    isMobile,
    isTablet,
    isDesktop,
    keyRoute,
    query,
    blogCategories,
    subcategories,
    headerMenus,
    isDraft,
  }: LayoutProps) => {
    const [isMountedHeader, setIsMountedHeader] = useState<boolean>(false);
    const [typeLast, setTypeLast] = useState<EHeader>(typeRoute?.header?.type || EHeader.DEFAULT);

    const { cmsResult, shouldUseVariation } = useSanityData();

    useEventPageView(cmsResult, shouldUseVariation);
    useEventMixpanelPageViewed();
    const { asPath, locale } = useRouter();

    const isHeaderHidden = useMemo(() => {
      if (typeRoute?.noHeader) {
        const {
          isPhone: isPhoneHeader,
          isMobile: isMobileHeader,
          isTablet: isTabletHeader,
          isDesktop: isDesktopHeader,
        } = typeRoute.noHeader;
        if (isPhoneHeader && isPhone) {
          return true;
        } else if (isMobileHeader && isMobile) {
          return true;
        } else if (isTabletHeader && isTablet) {
          return true;
        } else if (isDesktopHeader && isDesktop) {
          return true;
        }
      }

      return typeRoute?.noHeader?.isHidden;
    }, [isDesktop, isMobile, isPhone, isTablet, typeRoute?.noHeader]);

    const memoHeader = useMemo(() => {
      if (!isHeaderHidden) {
        if (typeRoute) {
          const {
            header: {
              type = EHeader.DEFAULT,
              shouldShowBanner = false,
              shouldShowCurrencyLink = false,
            },
          } = typeRoute;

          let isTransform = false;
          if (type !== typeLast) {
            isTransform = isMountedHeader;
          }
          setTypeLast(type);

          return (
            <HeaderContainer
              subcategories={subcategories}
              blogCategories={blogCategories}
              menus={headerMenus}
              shouldShowCurrencyLink={shouldShowCurrencyLink}
              shouldShowBanner={shouldShowBanner}
              header={typeRoute.header}
              headerType={typeRoute.header.type as EHeader}
              isTransform={isTransform}
              setIsMounted={setIsMountedHeader}
            />
          );
        }

        setTypeLast(EHeader.DEFAULT);

        return (
          <HeaderContainer
            subcategories={subcategories}
            shouldShowCurrencyLink={false}
            menus={headerMenus}
            header={typeRoute.header}
            headerType={typeRoute.header.type as EHeader}
          />
        );
      }
      return <></>;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMountedHeader, typeRoute, keyRoute, query]);

    const isFooterHidden = useMemo(() => {
      if (typeRoute?.noFooter) {
        const {
          isPhone: isPhoneFooter,
          isMobile: isMobileFooter,
          isTablet: isTabletFooter,
          isDesktop: isDesktopFooter,
        } = typeRoute.noFooter;
        if (isPhoneFooter && isPhone) {
          return true;
        } else if (isMobileFooter && isMobile) {
          return true;
        } else if (isTabletFooter && isTablet) {
          return true;
        } else if (isDesktopFooter && isDesktop) {
          return true;
        }
      }

      return typeRoute?.noFooter?.isHidden;
    }, [isDesktop, isMobile, isPhone, isTablet, typeRoute?.noFooter]);

    const memoFooter = useMemo(() => {
      const {
        header: { shouldShowCurrencyLink = false },
      } = typeRoute;

      return (
        <>
          {!isFooterHidden && typeRoute?.footer === EFooter.DEFAULT && (
            <Footer
              isShort={typeRoute?.header?.type === EHeader.SHORT}
              haveSubscribe={typeRoute?.header?.type === EHeader.DEFAULT}
              shouldShowCurrencyLink={
                shouldShowCurrencyLink &&
                query.slug.length === 1 &&
                Object.values(ECurrency).includes(query.slug[0].toUpperCase() as ECurrency)
              }
            />
          )}
          {!isFooterHidden && typeRoute?.footer === EFooter.LOGOS && <FooterWithLogos />}
        </>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFooterHidden, typeRoute?.footer, typeRoute?.header?.type, keyRoute, query]);

    return (
      <>
        {(typeRoute.accessRoute === EAccessRoute.CONNECTED || typeRoute.noIndex) && (
          <Head>
            <meta name="robots" content="noindex"></meta>
          </Head>
        )}
        {isDraft && (
          <Box position="fixed" bottom="20px" left="20px" zIndex={EZIndexName.FIXED}>
            <Link href={`/api/disable-draft?path=${locale}${asPath}`}>
              <Button variant={EButtonVariant.OUTLINED} zIndex={EZIndexName.TOOLTIP}>
                <Typography color={EColor.SECONDARY}>Draft mode : Back to published</Typography>
              </Button>
            </Link>
          </Box>
        )}
        {memoHeader}
        {children}
        {memoFooter}
      </>
    );
  },
);

Layout.displayName = 'Layout';
