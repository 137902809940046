import { useEffect, useRef, useState, ReactNode } from 'react';

import { ModalProvider as Provider } from '@core/context';

export type ProviderModalProps = {
  children?: ReactNode;
};

export const ProviderModal = ({ children }: ProviderModalProps) => {
  const modalNode = useRef<HTMLDivElement | null>(null);
  const [stateModalNode, setStateModalNode] = useState<Element | null>(null);

  useEffect(() => {
    setStateModalNode(modalNode.current);
  }, [setStateModalNode, modalNode]);

  return (
    <Provider value={stateModalNode}>
      {children}
      <div ref={modalNode} />
    </Provider>
  );
};
