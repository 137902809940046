import { FC, useEffect, useRef, useState } from 'react';
import styled, { CSSObject, useTheme } from 'styled-components';

import { useContextMediaQuery } from '@core/context';
import {
  EAccordion,
  EAccordionClosingType,
  EColor,
  EIcon,
  EPosition,
  ESize,
  ThemeProps,
  WithThemeProps,
} from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { Icon } from '../icon';
import { BoxProps, StyledAccordionWrapperProps } from '../interface';
import { Box } from '../layout';
import { AccordionProps } from './interface-accordion';

const ANIMATION_DURATION = 300;

export const Accordion = ({
  type,
  titlePadding,
  titleContent,
  titleActiveContent,
  children,
  closingType = EAccordionClosingType.HEADER_CLICK,
  defaultIcon = EIcon.ANGLE,
  activeIcon = EIcon.ANGLE,
  iconSize = ESize.MD,
  iconColor,
  iconPosition = EPosition.CENTER,
  iconBoxSize,
  iconHoverColor,
  iconActiveColor,
  titleBgColor,
  titleOpenBgColor,
  contentPadding,
  titleBorderRadius,
  hasBackgroundColor = true,
  isFixedHeight = true,
  onClickArrow,
  onAnimationEnd,
  ...restProps
}: AccordionProps & BoxProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isOpenState, setIsOpen] = useState<boolean>(
    'isOpenByDefault' in restProps ? restProps.isOpenByDefault : false,
  );
  const isOpen = 'isOpen' in restProps ? restProps.isOpen : isOpenState;

  const [height, setHeight] = useState<number>(0);

  const {
    structure: {
      accordion: { base: baseStyles, body: bodyStyles },
    },
  } = useTheme() as ThemeProps;

  const clickHandler = (currentClosingType: EAccordionClosingType) => {
    if (closingType === currentClosingType) {
      setIsOpen((prev) => !prev);

      if (onClickArrow) {
        onClickArrow();
      }

      if (onAnimationEnd) {
        setTimeout(() => {
          onAnimationEnd();
        }, ANIMATION_DURATION);
      }
    }
  };

  const getCursorType = (currentClosingType: EAccordionClosingType) => {
    return closingType === currentClosingType ? 'pointer' : 'unset';
  };

  useEffect(() => {
    if (isOpen) {
      const { height } = ref.current.getBoundingClientRect();
      setHeight(height);
    } else {
      setHeight(0);
    }
  }, [isOpen, children]);

  return (
    <StyledAccordionWrapper
      {...baseStyles[type]}
      {...restProps}
      isOpen={isOpen}
      type={type}
      hasBackground={hasBackgroundColor}
      cursor={getCursorType(EAccordionClosingType.FULL_CLICK)}
      onClick={() => clickHandler(EAccordionClosingType.FULL_CLICK)}
    >
      <Box
        cursor={getCursorType(EAccordionClosingType.HEADER_CLICK)}
        onClick={() => clickHandler(EAccordionClosingType.HEADER_CLICK)}
        width={'100%'}
        display={'flex'}
        alignItems={iconPosition === EPosition.TOP ? 'flex-start' : 'center'}
        justifyContent={'space-between'}
        padding={titlePadding}
        backgroundColor={isOpen ? titleOpenBgColor : titleBgColor}
        borderRadius={titleBorderRadius}
      >
        <Box width={'100%'}>{isOpen && titleActiveContent ? titleActiveContent : titleContent}</Box>
        <Box
          borderRadius={titleBorderRadius}
          minHeight={iconBoxSize}
          minWidth={iconBoxSize}
          hoverStyles={iconHoverColor && { backgroundColor: iconHoverColor }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Icon
            marginLeft={iconBoxSize ? '0' : '12px'}
            flexShrink={'0'}
            cursor={getCursorType(EAccordionClosingType.ACTION_CLICK)}
            onClick={() => clickHandler(EAccordionClosingType.ACTION_CLICK)}
            size={iconSize}
            type={isOpen ? activeIcon : defaultIcon}
            color={isOpen ? iconActiveColor : iconColor}
            orientation={isOpen ? EPosition.TOP : EPosition.BOTTOM}
          />
        </Box>
      </Box>
      <Box {...bodyStyles} height={isFixedHeight ? height : isOpen ? undefined : '0'}>
        <Box ref={ref} padding={isOpen ? contentPadding : '0'}>
          {children}
        </Box>
      </Box>
    </StyledAccordionWrapper>
  );
};

const StyledAccordionWrapper: FC<StyledAccordionWrapperProps> = styled(Box)(({
  theme: { palette },
  isOpen,
  type,
  onClick: _onClick,
  onMouseLeave: _onMouseLeave,
  onMouseEnter: _onMouseEnter,
  animation: _animation,
  hasBackground,
  ...restProps
}: StyledAccordionWrapperProps & WithThemeProps) => {
  const { getBackgroundColor } = getPaletteHandlers(palette);
  const { isDesktop } = useContextMediaQuery();
  const backgroundColor = getBackgroundColor(EColor.INFO);
  const isFAQ = type === EAccordion.FAQ;

  return {
    ...restProps,
    backgroundColor: isOpen && hasBackground && isFAQ && backgroundColor,
    '&:hover': {
      backgroundColor: isDesktop && isFAQ && backgroundColor,
    },
  } as CSSObject;
});
