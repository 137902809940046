import useTranslation from 'next-translate/useTranslation';

import { Translate } from '@core/constant';
import { EActionAttribute, EColor } from '@core/type';

import { useContextCrisp } from '@crisp';

import { Link } from './Link';
import { LinkLiveChatProps } from './interface-link';

export const LinkLiveChat = ({ text, ...props }: LinkLiveChatProps): JSX.Element => {
  const { openChat } = useContextCrisp();
  const { t } = useTranslation(Translate.common.BUTTON);

  return (
    <Link as={EActionAttribute.BUTTON} onClick={openChat} color={EColor.ACCENT} {...props}>
      {text ?? t('liveChat.title')}
    </Link>
  );
};
