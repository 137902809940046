import parse from 'html-react-parser';
import useTranslation from 'next-translate/useTranslation';
import { FC, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Box, ButtonIcon, Typography } from '@core/component';
import { HEADER_CONTAINER_WIDTH, Translate } from '@core/constant';
import { useMediaQuery } from '@core/provider';
import { EColor, EFontWeight, EIcon, ESize, ETypographyVariant } from '@core/type';

import { ConnectedBannerProps } from './interface-connected-banner';

const StyledTypography = styled(Typography)`
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: inline;
  }
`;

export const ConnectedBanner: FC<ConnectedBannerProps> = ({
  color,
  subtitle,
  onShowMore,
  onClose,
}) => {
  const { isPhone, isMobile } = useMediaQuery();

  const { t } = useTranslation(Translate.common.WIDGET);

  const measureRef = useRef<HTMLParagraphElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (measureRef.current) {
        const { clientWidth, scrollWidth } = measureRef.current;
        setIsTruncated(scrollWidth > clientWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box
      role={'alert'}
      height={'32px'}
      backgroundColor={color}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      padding={'0 20px'}
    >
      <Box
        maxWidth={isMobile ? 'calc(100vw - 40px)' : `${HEADER_CONTAINER_WIDTH}px`}
        position={'relative'}
      >
        <Box
          role={'button'}
          onClick={onShowMore}
          cursor={'pointer'}
          color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
          display={'flex'}
          justifyContent={'center'}
          fontWeight={EFontWeight.BOLD}
          padding={isPhone ? '0 28px 0 0' : isMobile ? '0 40px' : '0 90px'}
          maxWidth={'calc(100vw - 40px)'}
        >
          <StyledTypography
            ref={measureRef}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            variant={ETypographyVariant.BODY3}
          >
            {parse(subtitle)}
          </StyledTypography>
          {isTruncated && (
            <Typography
              textDecoration={'underline'}
              flexShrink={'0'}
              variant={ETypographyVariant.BODY3}
              marginLeft="4px"
            >
              {t('connectedBanner.seeMore')}
            </Typography>
          )}
        </Box>
        <ButtonIcon
          onClick={onClose}
          position={'absolute'}
          right={'-4px'}
          top={'4px'}
          iconProps={{
            type: EIcon.CROSS,
            flexShrink: 0,
            size: ESize.XS,
            color: { commonType: EColor.WHITE, intensity: EColor.R900 },
          }}
        />
      </Box>
    </Box>
  );
};
