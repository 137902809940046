import parse from 'html-react-parser';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';

import { Box, Button, Modal, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { EButtonVariant, ETypographyVariant } from '@core/type';

import { ConnectedBannerModalProps } from './interface-connected-banner';

export const ConnectedBannerModal: FC<ConnectedBannerModalProps> = ({
  isOpen,
  onCloseModal,
  title,
  subtitle,
}) => {
  const { t } = useTranslation(Translate.common.WIDGET);

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal}>
      <Modal.Header>
        <Typography variant={ETypographyVariant.H4} textAlign={'center'}>
          {title}
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Box textAlign={'center'} overflow={'hidden'}>
          {parse(subtitle)}
        </Box>
      </Modal.Body>
      <Modal.Footer display="flex" justifyContent="center">
        <Button variant={EButtonVariant.OUTLINED} onClick={onCloseModal}>
          {t('connectedBanner.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
