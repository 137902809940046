import { useSearchParams } from 'next/navigation';
import { useState, useEffect } from 'react';

import { MOBILE_APP_VISIT } from '@core/constant';
import { getItem, setItem } from '@core/storage';
import { EStorageType } from '@core/type';

export const useMobileAppVisit = (): boolean => {
  const searchParams = useSearchParams();
  const [isMobileAppVisit, setIsMobileAppVisit] = useState(false);

  useEffect(() => {
    if (searchParams.get(MOBILE_APP_VISIT) === 'true') {
      setItem(EStorageType.LOCAL_STORAGE, MOBILE_APP_VISIT, 'true');
      setIsMobileAppVisit(true);
    } else {
      const storedValue = getItem(EStorageType.LOCAL_STORAGE, MOBILE_APP_VISIT);
      if (storedValue === 'true') {
        setIsMobileAppVisit(true);
      }
    }
  }, [searchParams]);

  return isMobileAppVisit;
};
