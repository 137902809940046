import { themeContainerMaxWidth } from '@core/constant';
import {
  EAccordion,
  EFontWeight,
  ESize,
  ETypographyVariant,
  ThemeDevice,
  ThemeDeviceReturn,
  ThemeStructure,
  ThemeTypography,
} from '@core/type';

import { themeMD } from './theme-md';

const themeStructureSM = (baseTheme: ThemeDevice): ThemeStructure => ({
  ...baseTheme.structure,
  pendingGift: {
    wrapper: {
      margin: '25px auto',
    },
    descriptionText: {
      marginBottom: '20px',
    },
  },
  giftRecipient: {
    paper: {
      padding: '15px',
    },
  },
  receiveLatestSpotlights: {
    wrapper: {
      ...baseTheme.structure.receiveLatestSpotlights.wrapper,
      flexDirection: 'column',
    },
    buttonTextWrapper: {
      flexDirection: 'column',
    },
  },
  charts: {
    ...baseTheme.structure.charts,
    livePriceDisplay: 'block',
    headerBox: {
      padding: '24px 20px 24px',
      margin: '0 -20px',
    },
    chartPaper: { maxWidth: 'none', padding: '30px 0 0' },
  },
  container: {
    ...baseTheme.structure.container,
    maxWidth: themeContainerMaxWidth[ESize.SM],
  },
  mainContainer: {
    ...baseTheme.structure.mainContainer,
    minHeight: '543px',
  },
  toast: {
    ...baseTheme.structure.toast,
    wrapper: {
      ...baseTheme.structure.toast.wrapper,
      maxWidth: 'calc(100% - 60px)',
      right: '30px',
      top: '100px',
    },
  },
  cartPopup: {
    wrapper: {
      maxWidth: '358px',
      margin: '0',
    },
  },
  paper: {
    ...baseTheme.structure.paper,
    form: {
      ...baseTheme.structure.paper.form,
      maxWidth: '410px',
      hasElevation: false,
    },
  },
  header: {
    ...baseTheme.structure.header,
    short: {
      ...baseTheme.structure.header.short,
      height: '56px',
      padding: '20px 0',
    },
  },
  footer: {
    ...baseTheme.structure.footer,
    subscribe: {
      ...baseTheme.structure.footer.subscribe,
      padding: '16px 0px',
      flexDirection: 'column',
      height: 'inherit',
    },
  },
  tab: {
    ...baseTheme.structure.tab,
    padding: '20px 18px 12px',
  },
  productCard: {
    ...baseTheme.structure.productCard,
    base: {
      ...baseTheme.structure.productCard.base,
      height: '324px',
    },
    content: {
      ...baseTheme.structure.productCard.content,
      base: {
        ...baseTheme.structure.productCard.content.base,
        padding: '16px',
      },
    },
    imageContainer: {
      ...baseTheme.structure.productCard.imageContainer,
      height: '120px',
    },
  },
  accordion: {
    ...baseTheme.structure.accordion,
    base: {
      ...baseTheme.structure.accordion.base,
      [EAccordion.FAQ]: {
        ...baseTheme.structure.accordion.base[EAccordion.FAQ],
        borderRadius: 0,
        margin: '0 -20px',
      },
    },
  },
  tooltip: {
    ...baseTheme.structure.tooltip,
    body: { ...baseTheme.structure.tooltip.body, width: '324px' },
  },
});

const themeTypographySM = (baseTheme: ThemeDevice): ThemeTypography => ({
  ...baseTheme.typography,
  [ETypographyVariant.H1]: {
    fontSize: '24px',
    lineHeight: '1.34',
    fontWeight: EFontWeight.BOLD,
  },
  header: {
    ...baseTheme.typography.header,
    metals: {
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
});

export const themeSM: ThemeDeviceReturn = (baseTheme: ThemeDevice = themeMD()): ThemeDevice => ({
  typography: themeTypographySM(baseTheme),
  structure: themeStructureSM(baseTheme),
});
