import useTranslation from 'next-translate/useTranslation';

import { Box, DrawerLine, ErrorDot, Icon, Typography } from '@core/component';
import { DrawerCloseProps, EDrawerLevel } from '@core/component/interface';
import { Translate } from '@core/constant';
import { useContextProfile, useContextUser } from '@core/context';
import {
  EActionAttribute,
  EColor,
  EIcon,
  EPosition,
  ERouting,
  ESize,
  ETypographyVariant,
} from '@core/type';

import { MobileDrawer } from './MobileDrawer';

export const HelloUser = ({
  handleDrawerLevelProps,
  drawerLevelProps,
  isBlogPage,
  closeDrawer,
}: DrawerCloseProps & { isBlogPage?: boolean }) => {
  const { hasNotCompletedStep, hasStorageFeesUnpaid } = useContextProfile();
  const { firstName, lastName } = useContextUser();
  const { t: tHeader } = useTranslation(Translate.layout.HEADER);

  return (
    <Box padding={'0 20px 40px'}>
      {isBlogPage ? (
        <Box padding={'20px 30px'} borderRadius={'5px'} backgroundColor={EColor.SECONDARY}>
          <Icon
            display={'inline-block'}
            type={EIcon.USER}
            color={EColor.ACCENT}
            marginRight={'8px'}
            marginBottom={'-2px'}
          />
          <Typography
            as={EActionAttribute.SPAN}
            variant={ETypographyVariant.H5}
            color={EColor.ACCENT}
          >
            {tHeader('menu.helloUser', { firstName, lastName })}
          </Typography>
        </Box>
      ) : (
        <DrawerLine
          closeDrawer={closeDrawer}
          route={ERouting.HOME_BUY}
          drawerLevel={EDrawerLevel.USER}
          handleDrawerLevelProps={handleDrawerLevelProps}
          drawerLevelProps={drawerLevelProps}
          padding={'0'}
          hasIcon={false}
          title={tHeader('menu.account')}
          titleContent={
            <Box padding={'20px 30px'} borderRadius={'5px'} backgroundColor={EColor.SECONDARY}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Box>
                  <Box marginBottom={'8px'}>
                    {(hasNotCompletedStep || hasStorageFeesUnpaid) && (
                      <ErrorDot marginRight={'6px'} />
                    )}

                    <Icon
                      display={'inline-block'}
                      type={EIcon.USER}
                      color={EColor.ACCENT}
                      marginRight={'10px'}
                      marginBottom={'-2px'}
                    />

                    <Typography
                      as={EActionAttribute.SPAN}
                      variant={ETypographyVariant.H5}
                      color={EColor.ACCENT}
                    >
                      {tHeader('menu.helloUser', { firstName, lastName })}
                    </Typography>
                  </Box>

                  <Typography>{tHeader('menu.account')}</Typography>
                </Box>

                <Icon
                  type={EIcon.ANGLE}
                  orientation={EPosition.RIGHT}
                  size={ESize.MD}
                  flexShrink={'0'}
                  margin={'-1px 0'}
                />
              </Box>
            </Box>
          }
        >
          <MobileDrawer closeDrawer={closeDrawer} />
        </DrawerLine>
      )}
    </Box>
  );
};
