import Image, { StaticImageData } from 'next/image';

import IconGaLogoFullSvg from './svgs/icon-ga-logo-full.svg';

export const IconGaLogoFull = () => (
  <Image
    src={IconGaLogoFullSvg as StaticImageData}
    alt="Loader Gold Avenue icon"
    width={184}
    height={32}
  />
);
