import Image, { StaticImageData } from 'next/image';

import IconFlexibleChoiceSvg from './svgs/icon-flexible-choice.svg';

export const IconFlexibleChoice = () => (
  <Image
    src={IconFlexibleChoiceSvg as StaticImageData}
    alt="Flexible choice icon"
    width={80}
    height={80}
  />
);
