import useTranslation from 'next-translate/useTranslation';

import { Box, Icon, LinkTranslatedStyledAsLink } from '@core/component';
import { DrawerCloseProps } from '@core/component/interface';
import { Translate } from '@core/constant';
import { EColor, EIcon, EPosition, ERouting, ESize, ETypographyVariant } from '@core/type';

export const MyWallet = ({ closeDrawer }: DrawerCloseProps) => {
  const { t: tHeader } = useTranslation(Translate.layout.HEADER);

  return (
    <Box
      role={'button'}
      cursor={'pointer'}
      display={'flex'}
      gap={'16px'}
      justifyContent={'space-between'}
      alignItems={'center'}
      padding="8px 40px 8px 40px"
      marginTop="8px"
    >
      <LinkTranslatedStyledAsLink
        keyRoute={ERouting.WALLET}
        iconColor={EColor.ACCENT}
        isIconFill={false}
        isUnderlinedHover
        verticalAlign="middle"
        variant={ETypographyVariant.H6}
        onClick={closeDrawer}
      >
        {tHeader('menu.myWallet')}
      </LinkTranslatedStyledAsLink>
      <Icon
        type={EIcon.ARROW_DOWN}
        orientation={EPosition.RIGHT}
        size={ESize.SM}
        flexShrink={'0'}
        margin={'-1px 0'}
        color={EColor.ACCENT}
      />
    </Box>
  );
};
