import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';

import {
  Box,
  Line,
  LinkTranslatedStyledAsButton,
  LinkTranslatedStyledAsLink,
} from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery, useContextUser } from '@core/context';
import { EActionAttribute, EColor, EPosition, ERouting, ESize } from '@core/type';
import { SelectWithLngAndCur } from '@core/widget';

const HeaderBlogContainer = () => {
  const { isMobile } = useContextMediaQuery();
  const { isLogged } = useContextUser();
  const { t: tHeader } = useTranslation(Translate.layout.HEADER);

  return (
    !isMobile && (
      <Box display={'flex'} height={'36px'} alignItems={'center'} gap={'32px'}>
        <LinkTranslatedStyledAsLink keyRoute={ERouting.HOME} fontSize={'15px'}>
          {tHeader('blog.whatIsGa')}
        </LinkTranslatedStyledAsLink>

        <Line
          isVertical
          margin={'0'}
          height={'14px'}
          color={{ commonType: EColor.GRAY, intensity: EColor.A400 }}
        />

        <Box>
          <SelectWithLngAndCur
            withCurrency={false}
            position={EPosition.TOP_RIGHT}
            shouldShowCurrencyLink={false}
          />
        </Box>

        {!isLogged && (
          <LinkTranslatedStyledAsButton
            keyRoute={ERouting.SIGN_UP}
            as={EActionAttribute.LINK}
            size={ESize.MD}
            lineHeight={'34px'}
            padding={'0 20px'}
          >
            {tHeader('menu.openAccount')}
          </LinkTranslatedStyledAsButton>
        )}
      </Box>
    )
  );
};

export const HeaderBlog = memo(HeaderBlogContainer);
