import type { ReactNode } from 'react';

import { userContext } from '@core/context';
import { EUserStatus } from '@core/type';
import { UserEntity } from '@core/type/api';
import { UserHook, UserState } from '@core/type/context';

import { storeHook } from '../helpers';
import { useUser } from '../hook';

export const ProviderUser = ({
  children,
  isBotDetected,
  isReady,
  isLogged,
  user,
}: {
  children?: ReactNode;
  isBotDetected?: boolean;
  isLogged?: boolean;
  isReady?: boolean;
  user?: UserEntity;
}) => {
  const hooks = storeHook<UserState, UserHook>(
    {
      userId: user?.userId,
      status: user?.status || EUserStatus.DEFAULT,
      email: user?.email || '',
      isReady: isReady || isBotDetected,
      isLogged: isLogged,
      hasNotCompletedStep: user?.hasNotCompletedStep || false,
      hasMfaSms: user?.hasMfaSms || false,
      referenceNumber: user?.referenceNumber || '',
      hasMfaTotp: user?.hasMfaTotp || false,
      isDisconnecting: false,
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phone,
      language: user?.language,
      currency: user?.currencyIso,
      isRedirectedToSignIn: false,
      storageFeesStatus: user?.storageFeesStatus,
    },
    useUser,
  );
  return <userContext.Provider value={hooks}>{children}</userContext.Provider>;
};
