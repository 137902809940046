import {DEBUG_BEAR} from '@core/constant';

export const DebugBearScript  = ({ debugBearKey, nonce }: { debugBearKey: string, nonce: string }): JSX.Element | null => {
  if (!debugBearKey) {
    return null;
  }

  return (
    <script src={`${DEBUG_BEAR.CDN_URL}/${debugBearKey}.js`} async nonce={nonce} />
  );
};
