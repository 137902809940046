import Image, { StaticImageData } from 'next/image';

import IconZeroPercentSvg from './svgs/icon-zero-percent.svg';

export const IconZeroPercent = () => (
  <Image
    src={IconZeroPercentSvg as StaticImageData}
    alt="Zero percent icon"
    width={96}
    height={96}
  />
);
