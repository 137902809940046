import useTranslation from 'next-translate/useTranslation';
import type { FC } from 'react';

import { Translate } from '@core/constant';

import { useContextCrisp } from '@crisp';

import { Button } from './Button';
import { ButtonCustomProps, ButtonLiveChatProps } from './interface-button';

export const ButtonLiveChat: FC<Omit<ButtonCustomProps, 'onClick'>> = ({
  text,
  ...props
}: Omit<ButtonCustomProps, 'onClick'> & ButtonLiveChatProps): JSX.Element => {
  const { openChat } = useContextCrisp();
  const { t } = useTranslation(Translate.common.BUTTON);

  return (
    <Button onClick={openChat} {...props}>
      {text ?? t('liveChat.title')}
    </Button>
  );
};
