import { Box } from '@core/component';
import { useContextUser } from '@core/context';

import { SideNavDefaultProps } from '../../../../interface-header';
import { CurrencyDrawerLine } from './CurrencyDrawerLine';
import { HelloUser } from './HelloUser';
import { LanguageDrawerLine } from './LanguageDrawerLine';
import { Menus } from './Menu';
import { SideNavFooter } from './SideNavFooter';

export const SideNavDefault = ({
  closeDrawer,
  drawerLevelProps,
  handleDrawerLevelProps,
  shouldShowCurrencyLink,
  menus,
}: SideNavDefaultProps) => {
  const { isLogged } = useContextUser();

  return (
    <>
      {isLogged && (
        <HelloUser
          closeDrawer={closeDrawer}
          handleDrawerLevelProps={handleDrawerLevelProps}
          drawerLevelProps={drawerLevelProps}
        />
      )}
      <Menus
        menus={menus}
        drawerLevelProps={drawerLevelProps}
        closeDrawer={closeDrawer}
        handleDrawerLevelProps={handleDrawerLevelProps}
        shouldShowCurrencyLink={shouldShowCurrencyLink}
      />
      <Box margin={'48px 0'}>
        <LanguageDrawerLine
          closeDrawer={closeDrawer}
          drawerLevelProps={drawerLevelProps}
          handleDrawerLevelProps={handleDrawerLevelProps}
        />
        <CurrencyDrawerLine
          closeDrawer={closeDrawer}
          drawerLevelProps={drawerLevelProps}
          handleDrawerLevelProps={handleDrawerLevelProps}
        />
      </Box>
      <SideNavFooter closeDrawer={closeDrawer} />
    </>
  );
};
