import Image, { StaticImageData } from 'next/image';

import IconCircularArrowSvg from './svgs/icon-circular-arrow.svg';

export const IconCircularArrow = () => (
  <Image
    src={IconCircularArrowSvg as StaticImageData}
    alt="Circular arrow icon"
    width={16}
    height={16}
  />
);
