import useTranslation from 'next-translate/useTranslation';

import { Box } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMetalPrice } from '@core/context';
import { EMetal, MetalPrice } from '@core/type';

import { LiveChartsLink } from './LiveChartsLink';
import { LiveChartsMetalLink } from './LiveChartsMetalLink';
import { PriceMetal } from './PriceMetal';

export const HeaderPrice = () => {
  const { prices } = useContextMetalPrice();
  const { t } = useTranslation(Translate.layout.HEADER);

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      textAlign={'center'}
      padding={'6px'}
    >
      <LiveChartsLink />
      {Object.entries(prices).map(([metal, metalPrice]: [string, MetalPrice]) => {
        const { deskbid = 0, name, priceChange = 0 } = metalPrice;
        return (
          <LiveChartsMetalLink metal={metal as EMetal} key={metal}>
            <PriceMetal title={t(name)} priceChange={priceChange} deskbid={deskbid} />
          </LiveChartsMetalLink>
        );
      })}
    </Box>
  );
};
