import Image, { StaticImageData } from 'next/image';

import IconHelveticorSecondarySvg from './svgs/icon-helveticor-secondary.svg';

export const IconHelveticorSecondary = () => (
  <Image
    src={IconHelveticorSecondarySvg as StaticImageData}
    alt="Helveticor icon"
    height={19}
    width={136}
  />
);
