import { metalToSlug } from '@core/constant';
import { ELanguageTags, EMetal } from '@core/type';
import type { CategoryEntity, CategoryResponse } from '@core/type/api';

const getCategories = (language: ELanguageTags): CategoryResponse => {
  return {
    categories: Object.values(EMetal).map(
      (metal, index) =>
        ({
          id: index,
          position: index,
          title:
            metalToSlug[language][metal].charAt(0).toUpperCase() +
            metalToSlug[language][metal].slice(1),
          slug: metalToSlug[language][metal],
          metalIso: metal,
          description: null,
          metas: null,
        }) as CategoryEntity,
    ),
  };
};

export class CategoriesService {
  public static readonly getCategories = getCategories;
}
