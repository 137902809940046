import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { EColor, ESize, WithThemeProps } from '@core/type';

import { modalClosingAnimation, modalOpeningAnimation } from '../animation';
import { Box } from '../layout';
import { ModalBase } from './ModalBase';
import {
  StyledContentWrapperProps,
  ModalBaseBackgroundProps,
  WrapperProps,
  ContentWrapperProps,
  ContentWrapperBaseProps,
} from './interface-modal';

const ContentWrapperBase = forwardRef<HTMLDivElement, ContentWrapperBaseProps>(
  ({ children, isFull, ...props }: ContentWrapperBaseProps, ref) => (
    <Box
      borderRadius={isFull ? 'none' : '8px'}
      position="relative"
      margin={isFull ? '0' : '24px 0'}
      {...props}
      ref={ref}
    >
      {children}
    </Box>
  ),
);
ContentWrapperBase.displayName = 'ContentWrapperBase';

const ContentWrapper = styled(ContentWrapperBase)<ContentWrapperProps>`
  ${(props: ContentWrapperProps & WithThemeProps) => {
    const {
      theme: {
        palette,
        structure: {
          modalContainer: { size: structureSize },
        },
      },
      size: sizeProps = ESize.MD,
      maxWidth,
      isFull,
    } = props;

    const sizeRules = !isFull
      ? maxWidth
        ? { maxWidth }
        : structureSize[sizeProps]
      : { minHeight: '100vh' };

    const rules = {
      backgroundColor: palette[EColor.BACKGROUND][EColor.NEUTRAL][EColor.LIGHT],
      width: '100%',
      ...sizeRules,
    };

    return css`
      ${rules};
    `;
  }}
`;

const StyledContentWrapper = styled(ContentWrapper)`
  animation: ${({ isClosing }: StyledContentWrapperProps) =>
    isClosing
      ? css`
          ${modalClosingAnimation} 0.3s ease-out
        `
      : css`
          ${modalOpeningAnimation} 0.3s ease-out
        `};
`;

const ModalWrapper = forwardRef<HTMLDivElement, WrapperProps>(
  ({ size, isClosing, children, maxWidth, isFull }: WrapperProps, ref) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100%"
        margin="0 auto"
      >
        <StyledContentWrapper
          isFull={isFull}
          size={size}
          isClosing={isClosing}
          ref={ref}
          maxWidth={maxWidth}
        >
          {children}
        </StyledContentWrapper>
      </Box>
    );
  },
);
ModalWrapper.displayName = 'ModalWrapper';

export const ModalStyled = ({
  isOpen,
  onCloseModal,
  children,
  size,
  hasCloseButton,
  maxWidth,
  isFull,
  isClosedOnBGClick,
}: ModalBaseBackgroundProps) => {
  return (
    <ModalBase
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      hasCloseButton={hasCloseButton}
      size={size}
      maxWidth={maxWidth}
      isFull={isFull}
      styledContentWrapper={ModalWrapper}
      isClosedOnBGClick={isClosedOnBGClick}
    >
      {children}
    </ModalBase>
  );
};
