import * as Bowser from 'bowser';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

import { WidgetService } from '@core/api';
import { Box, ButtonIcon, Icon, Link, Typography } from '@core/component';
import { APP_LINK_APP_STORE, APP_LINK_GOOGLE_PLAY, Translate } from '@core/constant';
import { EColor, EIcon, ESize, ETypographyVariant, EZIndexName } from '@core/type';

import { BannerMobileAppPromotionProps } from './interface-banner';

export const BannerMobileAppPromotionBase = ({
  isAndroid,
  onClose,
}: BannerMobileAppPromotionProps) => {
  const { t } = useTranslation(Translate.common.WIDGET);

  return (
    <Box position={'relative'} zIndex={EZIndexName.ABOVE_CRISP}>
      <Box
        width={'100%'}
        maxWidth={'100%'}
        backgroundColor={EColor.SECONDARY}
        padding={'8px 18px 8px 12px'}
        display={'flex'}
        alignItems={'center'}
        gap={'8px'}
        flexWrap={'nowrap'}
        position={'fixed'}
        bottom={0}
        left={0}
        right={0}
      >
        <Box display={'flex'} gap={'4px'}>
          <ButtonIcon
            iconProps={{ type: EIcon.CROSS, size: ESize.XXXS, color: EColor.SECONDARY }}
            onClick={onClose}
          />
          <Icon
            type={EIcon.GA_LOGO_WITH_BACKGROUND}
            width={'64px'}
            height={'64px'}
            flexShrink={0}
          />
        </Box>
        <Box flexGrow={1}>
          <Typography
            color={{
              commonType: EColor.BLACK,
              intensity: EColor.R900,
            }}
            variant={ETypographyVariant.BODY3}
          >
            {t('bannerMobileAppPromotion.savingsMadeSimple')}
          </Typography>
          <Typography color={EColor.SECONDARY} variant={ETypographyVariant.CAPTION3}>
            {t('bannerMobileAppPromotion.goldAvenue')}
          </Typography>
          <Typography color={EColor.SECONDARY} variant={ETypographyVariant.CAPTION1}>
            {t(
              `bannerMobileAppPromotion.${isAndroid ? 'freeOnTheGooglePlay' : 'freeOnTheAppStore'}`,
            )}
          </Typography>
        </Box>
        <Box flexShrink={0}>
          <Link
            target={'_blank'}
            href={isAndroid ? APP_LINK_GOOGLE_PLAY : APP_LINK_APP_STORE}
            color={EColor.ACCENT}
            fontSize={'18px'}
            textTransform={'uppercase'}
            onClick={onClose}
          >
            {t('bannerMobileAppPromotion.view')}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const BannerMobileAppPromotion = () => {
  const [isAndroid, setIsAndroid] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);

  useEffect(() => {
    const parser = Bowser.getParser(window.navigator.userAgent);

    setIsAndroid(parser.getOSName() === 'Android');

    const bannerState = WidgetService.getMobileAppPromotionBannerState();

    if (!bannerState && parser.getPlatformType() === 'mobile') {
      setIsBannerOpen(true);
    }
  }, []);

  const handleClose = () => {
    WidgetService.setMobileAppPromotionBannerState();
    setIsBannerOpen(false);
  };

  if (isBannerOpen) {
    return <BannerMobileAppPromotionBase isAndroid={isAndroid} onClose={handleClose} />;
  }

  return null;
};
