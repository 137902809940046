import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';

import { Translate } from '@core/constant';
import { EColor, EFontWeight, EIcon, EPosition, ESize } from '@core/type';

import { Icon } from '../icon';
import { Box } from '../layout/Box';
import { Typography } from '../typography/Typography';

const ButtonBackTopContainer = () => {
  const { t } = useTranslation(Translate.common.BUTTON);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Box
      onClick={handleClick}
      cursor={'pointer'}
      position={'absolute'}
      top={'-46px'}
      left={'0'}
      width={'100%'}
      height={'46px'}
      paddingTop={'2px'}
      display={'flex'}
      alignItems={'center'}
      flexDirection={'column'}
      textAlign={'center'}
    >
      <Icon type={EIcon.ANGLE} color={EColor.PRIMARY} orientation={EPosition.TOP} size={ESize.MD} />
      <Typography
        color={EColor.PRIMARY}
        fontSize={'10px'}
        lineHeight={'1'}
        fontWeight={EFontWeight.MEDIUM}
        textTransform={'uppercase'}
      >
        {t('backTop.title')}
      </Typography>
    </Box>
  );
};

export const ButtonBackTop = memo(ButtonBackTopContainer);
