import useTranslation from 'next-translate/useTranslation';

import { Box, Icon, LinkTranslatedStyledAsLink, Typography } from '@core/component';
import { DrawerCloseProps } from '@core/component/interface';
import { Translate } from '@core/constant';
import {
  EActionAttribute,
  EColor,
  EIcon,
  EPosition,
  ERouting,
  ESize,
  ETypographyVariant,
} from '@core/type';

export const ReferFriends = ({ closeDrawer }: DrawerCloseProps) => {
  const { t: tHeader } = useTranslation(Translate.layout.HEADER);

  return (
    <Box as={EActionAttribute.LI} marginTop={'40px'} marginBottom={'64px'}>
      <LinkTranslatedStyledAsLink keyRoute={ERouting.REFERRALS} onClick={closeDrawer}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Box maxWidth={'210px'} marginRight={'16px'}>
            <Typography variant={ETypographyVariant.H6} marginBottom={'8px'}>
              {tHeader('menu.referFriend')}
            </Typography>
            <Typography variant={ETypographyVariant.BODY3}>
              {tHeader('menu.referFriendAndEarnBonus')}
            </Typography>
          </Box>

          <Icon
            type={EIcon.ARROW_DOWN}
            orientation={EPosition.RIGHT}
            size={ESize.SM}
            flexShrink={'0'}
            color={EColor.ACCENT}
          />
        </Box>
      </LinkTranslatedStyledAsLink>
    </Box>
  );
};
