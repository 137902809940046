import Image, { StaticImageData } from 'next/image';

import Icon2StepAuth1Svg from './svgs/icon-2-step-auth1.svg';

export const Icon2StepAuth1 = () => (
  <Image
    src={Icon2StepAuth1Svg as StaticImageData}
    alt="2 step authentification 1st step icon"
    width={57}
    height={56}
  />
);
