import Image, { StaticImageData } from 'next/image';

import IconGaLogoFullColorFilledSvg from './svgs/icon-ga-logo-full-color-filled.svg';

export const IconGaLogoFullColorFilled = () => (
  <Image
    src={IconGaLogoFullColorFilledSvg as StaticImageData}
    alt="Loader Gold Avenue icon"
    width={184}
    height={32}
  />
);
