import useTranslation from 'next-translate/useTranslation';
import NextLink from 'next/link';
import { memo } from 'react';
import { useTheme } from 'styled-components';

import { Box, Icon, Line, Typography } from '@core/component';
import { APP_LINK_APP_STORE, APP_LINK_GOOGLE_PLAY, Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { EColor, EFontWeight, EIcon, ETypographyVariant, ThemeProps } from '@core/type';

const FooterOptionContainer = () => {
  const { isMobile } = useContextMediaQuery();
  const { t: tFooter } = useTranslation(Translate.layout.FOOTER);

  const {
    structure: {
      footer: {
        menu: {
          flexDirection,
          alignItems,
          optionsContainer: { padding },
        },
      },
    },
  } = useTheme() as ThemeProps;

  return (
    <Box
      display={'flex'}
      alignItems={alignItems}
      justifyContent={'space-between'}
      textAlign={'center'}
      padding={padding}
      flexDirection={flexDirection}
    >
      <Box
        display={'flex'}
        padding={isMobile ? '40px 0' : '0'}
        flexDirection={isMobile ? 'column' : 'row'}
        gap={isMobile ? '8px' : '4px'}
      >
        <NextLink href={APP_LINK_APP_STORE} target={'_blank'}>
          <Icon width={isMobile ? '150px' : '108px'} height={'auto'} type={EIcon.APP_STORE} />
        </NextLink>
        <NextLink href={APP_LINK_GOOGLE_PLAY} target={'_blank'}>
          <Icon width={isMobile ? '150px' : '108px'} height={'auto'} type={EIcon.GOOGLE_PLAY} />
        </NextLink>
      </Box>
      {isMobile && (
        <Line width={'100%'} color={{ commonType: EColor.WHITE, intensity: EColor.R100 }} />
      )}
      <Box
        display={'flex'}
        alignItems={alignItems}
        justifyContent={'center'}
        textAlign={'center'}
        flexDirection={isMobile ? 'column' : 'row'}
        padding={'40px 10px'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          flexWrap={'wrap'}
          margin={isMobile ? '0 0 40px 0' : '0 48px 0 0'}
        >
          <Typography
            color={{ commonType: EColor.WHITE, intensity: EColor.R700 }}
            variant={ETypographyVariant.CAPTION3}
            fontWeight={EFontWeight.BOLD}
            marginRight={'16px'}
            marginBottom={isMobile ? '10px' : '0'}
          >
            {tFooter('menu.paymentMethods.title')}
          </Typography>
          <Box
            gap={'10px'}
            display={'flex'}
            alignItems={'center'}
            width={isMobile ? '100%' : 'auto'}
          >
            <Icon type={EIcon.BANK_TRANSFER} height={'20px'} width={'112px'} />
            <Icon type={EIcon.VISA} height={'20px'} width={'30px'} />
            <Icon type={EIcon.MASTERCARD} height={'20px'} width={'30px'} />
          </Box>
        </Box>

        <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
          <Typography
            color={{ commonType: EColor.WHITE, intensity: EColor.R700 }}
            variant={ETypographyVariant.CAPTION3}
            fontWeight={EFontWeight.BOLD}
            marginRight={'16px'}
            marginBottom={isMobile ? '10px' : '0'}
          >
            {tFooter('menu.delivery.title')}
          </Typography>
          <Box
            gap={'10px'}
            display={'flex'}
            alignItems={'center'}
            width={isMobile ? '100%' : 'auto'}
          >
            <Icon type={EIcon.UPS} height={'20px'} width={'20px'} />
            <Icon type={EIcon.HELVETICOR} height={'20px'} width={'72px'} />
            <Icon type={EIcon.LOOMIS} height={'20px'} width={'36px'} />
            <Icon type={EIcon.SWISS_POST} height={'20px'} width={'60px'} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const FooterOption = memo(FooterOptionContainer);
