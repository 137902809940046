import { useEffect, useState } from 'react';

import { util } from '@core/constant';
import { useContextRouting } from '@core/context';
import { useDebounce, useQueryApi } from '@core/hook';
import { ERouting } from '@core/type';
import { EQueryKey, SearchSuggestionsEntity } from '@core/type/api';

import { UseSearchProps } from './interface-search';

export const useSearchOptions = ({ handleClose }: UseSearchProps) => {
  const { setRoute } = useContextRouting();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const searchDebounced = useDebounce<string>(searchTerm, util.DEBOUNCE);

  const { data } = useQueryApi<SearchSuggestionsEntity, { q?: string }>(
    EQueryKey.SEARCH_ALL,
    { q: searchDebounced },
    {
      staleTime: Infinity,
      enabled: searchDebounced.length > 1,
    },
  );

  useEffect(() => {
    if (data?.suggestions) {
      if (!data.suggestions.length) {
        setSuggestions([searchTerm]);
      } else {
        setSuggestions(data?.suggestions);
      }
    }
  }, [data, searchTerm]);

  const redirect = (sT: string) => {
    if (sT) {
      setRoute(ERouting.SEARCH, { q: sT });
      handleClose();
    }
  };

  const updateSearchTerm = (term: string) => {
    setSearchTerm(term);
  };

  return { suggestions, searchTerm, updateSearchTerm, redirect };
};
