import Image, { StaticImageData } from 'next/image';

import IconBankTransferSvg from '../svgs/icon-bank-transfer.svg';

export const IconBankTransfer = () => (
  <Image
    src={IconBankTransferSvg as StaticImageData}
    alt="Bank Transfer icon"
    height={20}
    width={113}
  />
);
