export enum EStorageKey {
  TOKEN = 'token',
  REFRESH_TOKEN = 'refreshToken',
  IS_RETRY = 'isRetry',
  LANGUAGE = ' lng',
  TRANSACTION = 'transaction',
  CURRENCY = 'currency',
  COUNTRY = 'country',
  BANNER_COOKIE_NAME = 'infoBannerToken',
  KEY_ROUTE = 'keyRoute',
  GIFTED_PRODUCTS = 'giftedProducts',
  SUBMITTED_GIFT_PRODUCTS = 'submittedGiftProducts',
  INTERMEDIATE_GIFT_RECIPIENT = 'giftRecipient',
  SUBMITTED_GIFT_RECIPIENT = 'submittedGiftRecipient',
  RECENTLY_VIEWED_PRODUCTS = 'recentlyViewedProducts',
  REFERENCE_NUMBER = 'referenceNumber',
  AS_BANNER_STATES = 'asBannerStates',
  STORAGE_FEES_BANNER_STATE = 'storageFeesBannerState',
  SESSION_ID = 'sessionId',
  CART = 'cart',
  CART_PRODUCTS = 'cartProducts',
  WALLET_SALES = 'walletSales',
  WIDGET_RATING_STARS = 'widgetRatingStars',
  WIDGET_MOBILE_APP_PROMOTION_BANNER = 'widgetMobileAppPromotionBanner',
  TOKEN_SAFERPAY = 'tokenSaferpay',
  AFFILIATE_SLUG = 'affiliate_slug',
}
