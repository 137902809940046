import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconFilters = ({ fill }: DynamicIconProps) => (
  <SVG width="24" height="24" viewBox="0 0 24 24" fill="none" title="Filters icon">
    <g clipPath="url(#clip0_2512_1884)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8421 4C18.2611 4 17.7895 4.46051 17.7895 5.02564C17.7895 5.59077 18.2611 6.05128 18.8421 6.05128C19.4232 6.05128 19.8947 5.59077 19.8947 5.02564C19.8947 4.46051 19.4232 4 18.8421 4ZM18.8421 2C20.5832 2 22 3.346 22 5C22 6.302 21.1179 7.401 19.8947 7.815V20.9744C19.8947 21.5408 19.4235 22 18.8421 22C18.2608 22 17.7895 21.5408 17.7895 20.9744V7.815C16.5663 7.401 15.6842 6.302 15.6842 5C15.6842 3.346 17.1011 2 18.8421 2Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9993 10.7179C12.5804 10.7179 13.052 11.1785 13.052 11.7436C13.052 12.3087 12.5804 12.7692 11.9993 12.7692C11.4183 12.7692 10.9467 12.3087 10.9467 11.7436C10.9467 11.1785 11.4183 10.7179 11.9993 10.7179ZM11.9993 2C11.418 2 10.9467 2.4592 10.9467 3.02564V8.85167C9.72354 9.26567 8.84144 10.3647 8.84144 11.6667C8.84144 12.9687 9.72354 14.0677 10.9467 14.4817V20.9744C10.9467 21.5408 11.418 22 11.9993 22C12.5807 22 13.052 21.5408 13.052 20.9744V14.4817C14.2751 14.0677 15.1572 12.9687 15.1572 11.6667C15.1572 10.3647 14.2751 9.26567 13.052 8.85167V3.02564C13.052 2.4592 12.5807 2 11.9993 2Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.15851 17.8974C5.73956 17.8974 6.21114 18.358 6.21114 18.9231C6.21114 19.4882 5.73956 19.9487 5.15851 19.9487C4.57746 19.9487 4.10588 19.4882 4.10588 18.9231C4.10588 18.358 4.57746 17.8974 5.15851 17.8974ZM6.21114 16.185V3.02564C6.21114 2.4592 5.73986 2 5.15851 2C4.57716 2 4.10588 2.4592 4.10588 3.02564V16.185C2.88272 16.599 2.00062 17.698 2.00062 19C2.00062 20.654 3.41746 22 5.15851 22C6.89956 22 8.31641 20.654 8.31641 19C8.31641 17.698 7.4343 16.599 6.21114 16.185Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_1884">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </SVG>
);
