import Image, { StaticImageData } from 'next/image';

import IconDeliveryVehicleSvg from './svgs/icon-delivery-vehicle.svg';

export const IconDeliveryVehicle = () => (
  <Image
    src={IconDeliveryVehicleSvg as StaticImageData}
    alt="Delivery vehicle icon"
    width={24}
    height={24}
  />
);
